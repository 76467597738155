import React from 'react';
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';

import { QuestionType } from '../../../Enums/Event';

import styles from './Question.module.css';

interface QuestionProps {
    data: QuestionForm;
    onFormChange: (form?: QuestionForm) => void;
}

function Question({ onFormChange, data }: QuestionProps) {
  const [txfDistractor, setTxfDistractor] = React.useState('');
  const [txfAcceptedAnswer, setTxfAcceptedAnswer] = React.useState('');

  const onChangeInput = (event: any) => {
    const { name, value } = event.target;
    onFormChange({
      ...data,
      [name]: value
    });
  };

  const addNewAnswerDistractor = (event: any) => {
    if (event.key === 'Enter') {
      const distractors = data.distractors;
      if (!distractors.includes(txfDistractor)) {
        distractors.push(txfDistractor);
        onFormChange({
          ...data,
          distractors
        });
      }
      setTxfDistractor('');
    }
  };

  const onDeleteAnswerDistractor = (value: string) => {
    const distractors = data.distractors.filter(element => element !== value);
    onFormChange({
      ...data,
      distractors
    });
  };

  const addNewAcceptedAnswer = (event: any) => {
    if (event.key === 'Enter') {
      const acceptedAnswers = data.acceptedAnswers;
      if (!acceptedAnswers.includes(txfAcceptedAnswer)) {
        acceptedAnswers.push(txfAcceptedAnswer);
        onFormChange({
          ...data,
          acceptedAnswers
        });
      }
      setTxfAcceptedAnswer('');
    }
  };

  const onDeleteAcceptedAnswer = (value: string) => {
    const acceptedAnswers = data.acceptedAnswers.filter(element => element !== value);
    onFormChange({
      ...data,
      acceptedAnswers
    });
  };

  return (
    <Grid container spacing={3} className={styles.root} padding={2}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="type-question-label">Type</FormLabel>
          <RadioGroup
            aria-labelledby="type-question-label"
            value={data.type}
            name="type"
            onChange={onChangeInput}
          >
            <FormControlLabel value={QuestionType.SpeakTheSentence} control={<Radio />} label="Speak The Sentence" />
            <FormControlLabel value={QuestionType.MultipleChoice} control={<Radio />} label="Multiple Choice" />
            <FormControlLabel value={QuestionType.TypeTheWords} control={<Radio />} label="Type The Words" />
            <FormControlLabel value={QuestionType.CTA} control={<Radio />} label="CTA" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {data.type === QuestionType.CTA &&
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Image URL"
                      name="ctaImage"
                      value={data.ctaImage}
                      variant="standard"
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="CTA Name"
                      name="ctaName"
                      value={data.ctaName}
                      variant="standard"
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Header"
                      name="ctaHeader"
                      value={data.ctaHeader}
                      variant="standard"
                      onChange={onChangeInput}
                    />
                  </Grid>
                </>
      }
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={data.type === QuestionType.CTA ? 'Support statement' : 'Sentence'}
          name="sentence"
          value={data.sentence}
          rows={5}
          multiline={data.type !== QuestionType.CTA}
          variant={data.type === QuestionType.CTA ? 'standard' : 'filled'}
          onChange={onChangeInput}
        />
      </Grid>
      {data.type === QuestionType.CTA &&
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      color="secondary"
                      label="Primary CTA Button text (CTA#1)"
                      name="ctaBtnPrimaryText"
                      value={data.ctaBtnPrimaryText}
                      variant="standard"
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      color="secondary"
                      label="Primary CTA link"
                      name="ctaPrimaryLink"
                      value={data.ctaPrimaryLink}
                      variant="standard"
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      color="secondary"
                      label="Secondary CTA Button text"
                      name="ctaBtnSecondaryText"
                      value={data.ctaBtnSecondaryText}
                      variant="standard"
                      onChange={onChangeInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      color="secondary"
                      label="Secondary CTA link"
                      name="ctaSecondaryLink"
                      value={data.ctaSecondaryLink}
                      variant="standard"
                      onChange={onChangeInput}
                    />
                  </Grid>
                </>
      }
      {data.type === QuestionType.MultipleChoice &&
                <>
                  <Grid item xs={12}>
                    <Paper className={styles.containerTags}>
                      <TextField
                        label="Accepted Answers"
                        helperText="Press Enter to add an Accepted Answer"
                        value={txfAcceptedAnswer}
                        onChange={(event) => setTxfAcceptedAnswer(event.target.value)}
                        onKeyPress={addNewAcceptedAnswer}
                        fullWidth
                        variant="standard"
                      ></TextField>
                      {data.acceptedAnswers.map((accepted: any, index: React.Key) =>
                        <Chip
                          key={index}
                          label={accepted}
                          color="primary"
                          onDelete={() => onDeleteAcceptedAnswer(accepted)}
                          className={styles.tag}
                        ></Chip>
                      )}
                    </Paper>

                  </Grid>
                  < Grid item xs={12}>
                    <Paper className={styles.containerTags}>
                      <TextField
                        label="Distractors"
                        helperText="Press Enter to add a distractor"
                        value={txfDistractor}
                        onChange={(event) => setTxfDistractor(event.target.value)}
                        onKeyPress={addNewAnswerDistractor}
                        fullWidth
                        variant="standard"
                      ></TextField>
                      {data.distractors.map((distractor: any, index: React.Key) =>
                        <Chip
                          key={index}
                          label={distractor}
                          color="primary"
                          onDelete={() => onDeleteAnswerDistractor(distractor)}
                          className={styles.tag}
                        ></Chip>
                      )}
                    </Paper>

                  </Grid>
                </>
      }
    </Grid >
  );
}

export default React.memo(Question);