import React from 'react';
import { Chip, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Icon, Radio, RadioGroup } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';

import EdButton from '../../components/EdButton';
import DialogTitle from '../../components/Dialog/DialogTitle';
import EdTypography from '../../components/EdTypography';
import EdProgress from '../../components/EdProgress';

import { ReactComponent as RefreshIcon } from '../../img/RefreshIcon.svg';

import { QuestionType } from '../../Enums/Event';

import styles from './paper.module.css';

interface InteractionAnalytics {
  activeUsers: number;
  answers: any[];
  question?: any;
  onLaunchInteraction: (interaction: any) => void;
  onClose: () => void;
}

function InteractionAnalytics({ onClose, question, answers, onLaunchInteraction }: InteractionAnalytics) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [assessments, setAssessments] = React.useState<any>({
    good: 0,
    neutral: 0,
    bad: 0,
    totalAnswers: 0
  });

  React.useEffect(() => {
    const analytics = answers.find((element: any) => element.id === question.id);
    if (analytics?.answers) {
      const totalAnswers = analytics.answers?.length || 0;
      switch (question?.type) {
      case QuestionType.TypeTheWords:
      case QuestionType.CTA:
      case QuestionType.MultipleChoice:
        const totalGood = analytics?.answers.filter((element: any) => element.is_correct === true).length || 0;
        const totalBad = analytics?.answers.filter((element: any) => element.is_correct === false).length || 0;
        setAssessments((values: any) => ({
          ...values,
          good: totalGood,
          bad: totalBad,
          totalAnswers
        }));
        break;
      case QuestionType.SpeakTheSentence:
        analytics.answers = analytics.answers.map((element: any) => {
          try {
            element.answer = JSON.parse(element.answer);
            return element;
          } catch (error) {
            element.answer = '';
            return element;
          }
        });
        const totalGoodScore = analytics?.answers
          .filter((element: any) => element.answer.pronunciationScore >= 75).length || 0;
        const totalNeutralScore = analytics?.answers
          .filter((element: any) => element.answer.pronunciationScore >= 55 && element.answer.pronunciationScore < 75).length || 0;
        const totalBadScore = analytics?.answers
          .filter((element: any) => element.answer.pronunciationScore < 55).length || 0;
        setAssessments((values: any) => ({
          ...values,
          good: totalGoodScore,
          neutral: totalNeutralScore,
          bad: totalBadScore,
          totalAnswers
        }));
        break;
      default:
        break;
      }
    }
    console.log(analytics);


  }, [answers]);

  const renderInteractionTitle = React.useCallback((question: any) => {
    switch (question?.type) {
    case QuestionType.SpeakTheSentence:
      return (
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
                microphone
            </Icon>
          }
          label="Speaking practice"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );
    case QuestionType.MultipleChoice:
      return (
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
                format_list_bulleted
            </Icon>
          }
          label="Multiple choice"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );
    case QuestionType.TypeTheWords:
      return (
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
                text_fields
            </Icon>
          }
          label="Type words"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );
    case QuestionType.CTA:
      return (
        <Chip
          icon={
            <RocketLaunchOutlinedIcon sx={{ color: '#C11574 !important', fontSize: '14px' }} />
          }
          label="CTA"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );

    default:
      break;
    }
  }, []);

  const renderInteractionSentence = React.useCallback((question: any) => {
    switch (question?.type) {
    case QuestionType.TypeTheWords:
    case QuestionType.SpeakTheSentence:
    case QuestionType.CTA:
      return <EdTypography variant="h5">{question?.sentence ?? ''}</EdTypography>;
    case QuestionType.MultipleChoice:
      const answers = [
        ...question?.accepted_answers,
        ...question?.distractors,
      ].sort();

      return (
        <FormControl>
          <RadioGroup value={undefined} name="answer">
            {answers.map((element, key) => (
              <FormControlLabel
                key={key}
                value={element}
                control={<Radio checked={question?.accepted_answers.some((a: any) => a === element)} />}
                label={element}
                disabled
              />
            ))}
          </RadioGroup>
        </FormControl>
      );

    default:
      break;
    }
  }, []);

  return (
    <>
      <Dialog
        open
        classes={{
          paper: fullScreen ? styles.dialog : styles.webDialog,
        }}
      >
        <DialogTitle id="interactions-dialog-title" onClose={onClose}>
          {renderInteractionTitle(question)}
        </DialogTitle>
        <DialogContent>
          {renderInteractionSentence(question)}
          <EdProgress
            variant="linear"
            color="success"
            label="Good"
            value={assessments.good}
            percentage={Math.round((assessments.good / assessments.totalAnswers) * 100)}
          />
          {question?.type === QuestionType.SpeakTheSentence &&
            <EdProgress
              variant="linear"
              color="info"
              label="Almost there"
              value={assessments.neutral}
              percentage={Math.round((assessments.neutral / assessments.totalAnswers) * 100)}
            />
          }
          <EdProgress
            variant="linear"
            color="error"
            label="Needs improvement"
            value={assessments.bad}
            percentage={Math.round((assessments.bad / assessments.totalAnswers) * 100)}
          />
          <EdTypography variant="subtitle2" align="center">{assessments.totalAnswers} people answered</EdTypography>
        </DialogContent>
        <DialogActions style={{ paddingLeft: '24px', paddingRight: '24px' }}>
          <EdButton
            variant="contained"
            color="secondary"
            startIcon={<RefreshIcon width="16px" height="16px" />}
            onClick={() => {
              onLaunchInteraction(question);
            }}
          >
            Launch again
          </EdButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default React.memo(InteractionAnalytics);
