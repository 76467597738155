const config = {
  env: 'staging',
  api: {
    security: {
      basePath: process.env.REACT_APP_BASEPATH_SECURITY_API || 'https://security-v2.stage.edfluence.app/api'
    },
    events: {
      basePath: process.env.REACT_APP_BASEPATH_EVENTS_API || 'https://events.stage.edfluence.app/api'
    },
    lessons: {
      basePath: process.env.REACT_APP_BASEPATH_LESSONS_API || 'https://lessons.stage.edfluence.app/api'
    }
  },
  socket: {
    chat: {
      basePath: process.env.REACT_APP_BASEPATH_CHAT_API || 'https://chat.stage.edfluence.app'
    }
  }
}

export default config;
