import React from 'react';

import styles from './EdListeningWave.module.css';

function EdListeningWave() {
  return (
    <div className={styles.root}>
      <div className={styles.pick} />
      <div className={styles.pick} />
      <div className={styles.pick} />
      <div className={styles.pick} />
      <div className={styles.pick} />
      <div className={styles.pick} />
      <div className={styles.pick} />
      <div className={styles.pick} />
    </div>
  );
}

export default React.memo(EdListeningWave);
