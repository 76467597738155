import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  signInWithEmailLink,
  isSignInWithEmailLink,
  signOut
} from 'firebase/auth';

import UserService from '../User';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export const auth = getAuth(app);

export const signInWithEmail = async (link: string) => {
  try {
    const email = window.localStorage.getItem('emailForSignIn') as string;
    const res = await signInWithEmailLink(auth, email, link);
    const token = await res.user.getIdToken();
    await UserService.signin(token);
    window.localStorage.removeItem('emailForSignIn');
  } catch (error) {
    console.log(error);
  }
};

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const token = await res.user.getIdToken();
    const authResponse = await UserService.signin(token);
    localStorage.setItem('ed-user-token', authResponse.token);
  } catch (error) {
    console.log(error);
  }
};

export const signInWithFacebook = async () => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const token = await res.user.getIdToken();
    await UserService.signin(token);
  } catch (error) {
    console.log(error);
  }
};

export const sendLinkToEmail = async (email: string) => {
  try {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
  } catch (error) {
    console.log(error);
  }
};

export const verifySignInWithEmailLink = (link: string) => {
  try {
    return isSignInWithEmailLink(auth, link);
  } catch (error) {
    console.log(error);
  }
};

export const logOut = () => {
  try {
    localStorage.removeItem('ed-user-token');
    return signOut(auth);
  } catch (error) {
    console.log(error);
  }
};
