import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import styles from './EdFeedback.module.css';

ChartJS.register(ArcElement, Tooltip, Legend);

interface EdFeedback {
  scoring: {
    speaking?: number,
    writing?: number,
    reading?: number,
  },
  maxPoints?: number
}

function EdFeedback({ scoring, maxPoints }: EdFeedback) {
  const [percentage, setPercentage] = React.useState(0);
  const [totalPoints, setTotalPoints] = React.useState(0);
  const data = {
    labels: Object.keys(scoring || {}).map(label => label.charAt(0).toUpperCase() + label.slice(1)),
    datasets: [
      {
        label: ' Points',
        data: Object.values(scoring || {}),
        backgroundColor: [
          'rgba(234, 221, 255, 1)',
          'rgba(111, 247, 243, 1)',
          'rgba(203, 230, 255, 1)',
        ]
      }
    ]
  };

  React.useEffect(() => {
    const total = Object.values(scoring).reduce((a, b) => a + b, 0);
    setTotalPoints(total);
    const totalPercentage = total * 100 / (maxPoints || 0);
    const roundedPercentage = Math.min(totalPercentage,100);
    const finalPercentage = isNaN(roundedPercentage) ? 0 : Math.round(roundedPercentage);
    setPercentage(finalPercentage);
  }, [maxPoints]);

  const pointsTotals = !Number.isInteger(totalPoints) ? totalPoints.toFixed(2) : totalPoints;

  return (
    <div className={styles.root}>
      <div className={styles.pointsContainer}>
        <div className={styles.percentage}>
          {percentage}%
        </div>
        <div className={styles.points}>
          {pointsTotals} out of {maxPoints}
        </div>
      </div>
      <Doughnut data={data} options={{ plugins: { legend: { position: 'bottom', labels: { usePointStyle: true } } } }} />
    </div>
  );
}

export default React.memo(EdFeedback);
