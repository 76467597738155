import React from 'react';
import { Chip, DialogContent, Typography } from '@mui/material';
import { DialogActions, Icon, Button } from '@mui/material';
import styles from '../paper.module.css';
import DialogTitle from '../../../components/Dialog/DialogTitle';

type CloseDialogProps = {
  onClose: () => void;
  onReScheduleLive: () => void;
  onEndLive: () => void;
};

const CloseDialog: React.FC<CloseDialogProps> = (props: CloseDialogProps) => {
  const { onClose, onReScheduleLive, onEndLive }: CloseDialogProps = props;
  return (
    <>
      <DialogTitle id="interactions-dialog-title" onClose={onClose}>
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
              warning_amber
            </Icon>
          }
          label="Alert"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        ></Chip>
      </DialogTitle>
      <DialogContent>
        <Typography className={styles.title}>You are leaving this livestream.</Typography>
        <Typography className={styles.title}>What do you want to do?</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none',
            borderRadius: '100px',
            background: '#DD2590',
            '&:hover': {
              bgcolor: '#DD2590'
            }
          }}
          onClick={onReScheduleLive}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none',
            borderRadius: '100px',
            background: '#DD2590',
            '&:hover': {
              bgcolor: '#DD2590'
            }
          }}
          onClick={onEndLive}
        >
          End Live Session
        </Button>
      </DialogActions>
    </>
  );
};

export default CloseDialog;
