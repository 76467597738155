import React from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Fab,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import EventForm from '../../components/EventForm';
import EdTypography from '../../components/EdTypography';

import EventService from '../../services/Events';
import UserService from '../../services/User';

import styles from './Admin.module.css';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((
  props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
  ref: React.Ref<unknown>,
) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Admin() {
  const [events, setEvents] = React.useState([]);
  const [channels, setChannels] = React.useState<any | null>([]);
  const [openFormEvent, setOpenFormEvent] = React.useState(false);
  const originUrl = window.location.origin;

  const navigate = useNavigate();

  React.useEffect((): any => {
    async function getEvents() {
      const eventsRequest = await EventService.getUserEvents();
      const userChannels = await UserService.getUserChannels();
      setChannels(userChannels);
      setEvents(eventsRequest.data);
    }
    getEvents();
  }, []);

  const handleStartEvent = (eventId: any) => {
    const url = `/admin/event/${eventId}`;
    navigate(url);
  };

  const handleLogout = () => {
    localStorage.removeItem('ispeak-token');
    localStorage.removeItem('edfluence-email');
    localStorage.removeItem('edfluence-username');
    navigate('/login', { replace: true });
  };

  const handleOnCloseDialog = (_: any, reason: string) => {
    if (reason === 'backdropClick') {
      return null;
    }
    setOpenFormEvent(false);
  };

  const handleSaveEvent = async (data: EventData) => {
    try {
      await EventService.createEvent(data);
      const eventsRequest = await EventService.getUserEvents();
      setEvents(eventsRequest.data);
      setOpenFormEvent(false);
    } catch (error) {
      console.log(error);
      setOpenFormEvent(false);
    }
  };

  return (
    <Container maxWidth={false} disableGutters className={styles.root}>
      <EdTypography variant="h3" align="center">User Events</EdTypography>
      <Button variant="contained" onClick={handleLogout}>Logout</Button>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>
                            ID
            </TableCell>
            <TableCell>
                            Title
            </TableCell>
            <TableCell>
                            Description
            </TableCell>
            <TableCell>
                            Start Date
            </TableCell>
            <TableCell>
                            Finish Date
            </TableCell>
            <TableCell>
                            Audience URL
            </TableCell>
            <TableCell>
                            Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event: any) =>
            <TableRow key={event.public_id}>
              <TableCell>{event.public_id}</TableCell>
              <TableCell>{event.title}</TableCell>
              <TableCell>{event.description}</TableCell>
              <TableCell>{moment(event.start_date).format('YYYY-MM-DD HH:mm')}</TableCell>
              <TableCell>{moment(event.finish_date).format('YYYY-MM-DD HH:mm')}</TableCell>
              <TableCell>{`${originUrl}/event/${event.public_id}`}</TableCell>
              <TableCell><Button onClick={() => handleStartEvent(event.public_id)}>Start</Button></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Fab color="primary" aria-label="add" size="large" className={styles.btnAdd} onClick={() => setOpenFormEvent(true)}>
        <AddIcon />
      </Fab>
      <Dialog
        onClose={handleOnCloseDialog}
        open={openFormEvent}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogContent>
          <EdTypography variant="subtitle1" className={styles.dialogTitle}>Create Event</EdTypography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenFormEvent(false)}
            className={styles.btnClose}
          >
            <CloseIcon />
          </IconButton>
          <EventForm channels={channels.data} onSave={handleSaveEvent} />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default React.memo(Admin);
