import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

import styles from './EdTypography.module.css';

interface EdTypography extends TypographyProps {
  children?: React.ReactNode
}

function EdTypography(props: EdTypography) {
  return (
    <Typography {...props} className={styles.root}>
      {props.children}
    </Typography>
  );
}

export default React.memo(EdTypography);
