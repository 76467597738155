const config = {
  env: 'development',
  api: {
    security: {
      basePath: process.env.REACT_APP_BASEPATH_SECURITY_API || 'http://localhost:3007/api'
    },
    events: {
      basePath: process.env.REACT_APP_BASEPATH_EVENTS_API || 'http://localhost:3005/api'
    },
    lessons: {
      basePath: process.env.REACT_APP_BASEPATH_LESSONS_API || 'http://localhost:3001/api'
    }
  },
  socket: {
    chat: {
      basePath: process.env.REACT_APP_BASEPATH_CHAT_API || 'http://localhost:4000'
    }
  }
}

export default config;
