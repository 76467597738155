import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import styles from '../paper.module.css';
import CloseDialog from './CloseDialog';
import EndDialog from './EndDialog';

type CloseSessionDialogProps = {
  onClose: () => void;
  onEndLive: () => void;
  onReScheduleLive: () => void;
};

const CloseSessionDialog: React.FC<CloseSessionDialogProps> = (props: CloseSessionDialogProps) => {
  const { onClose, onEndLive, onReScheduleLive }: CloseSessionDialogProps = props;
  const [step, setStep] = useState<'question' | 'confirm'>('question');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog
        open
        classes={{
          paper: fullScreen ? styles.dialog : styles.webDialog
        }}
      >
        {step === 'question' ? (
          <CloseDialog
            onClose={onClose}
            onReScheduleLive={onReScheduleLive}
            onEndLive={() => {
              setStep('confirm');
            }}
          />
        ) : (
          <EndDialog onClose={onClose} onEndLive={onEndLive} />
        )}
      </Dialog>
    </>
  );
};

export default CloseSessionDialog;
