import React from 'react';
import { Box, Avatar as MaterialAvatar } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

type AvatarProps = {
  src: string;
};

const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const { src } = props;
  return (
    <>
      <Box position="relative">
        <Box
          p={0.5}
          sx={{
            background: 'white',
            borderRadius: '50%',
            boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)'
          }}
        >
          <MaterialAvatar alt="profile" sx={{ width: 70, height: 70 }} src={src} />
        </Box>
        <VerifiedIcon
          sx={{
            color: '#0788F5',
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '20px',
            height: '20px'
          }}
        />
      </Box>
    </>
  );
};

export default Avatar;
