import React from 'react';
import { Icon, IconButton, InputAdornment, TextField } from '@mui/material';

import styles from './Chat.module.css';


interface Message {
  avatar: string;
  name: string;
  message: string
}

type ChatProps = {
  messages: Message[],
  onSend: (message: string) => void
}

function Chat({ messages, onSend }: ChatProps) {
  const [txfMessage, setTxfMessage] = React.useState('');
  const messagesContainer = React.useRef<any>();

  const handleSend = React.useCallback((e: any) => {
    e.preventDefault();
    if (txfMessage) {
      onSend(txfMessage);
      setTxfMessage('');
    }
  }, [onSend, txfMessage]);

  React.useEffect(() => {
    messagesContainer.current.scrollTo(0, messagesContainer.current.scrollHeight);
  }, [messages]);

  return (
    <div className={styles.root}>
      <div className={styles.messagesContainer} ref={messagesContainer}>
        {messages.map((element, key) =>
          <div key={key} className={styles.messageWrap}>
            <img className={styles.avatar} src={element.avatar} />
            <div className={styles.data}>
              <span className={styles.userName}>{element.name}</span>
              <span className={styles.message}>{element.message}</span>
            </div>
          </div>
        )}
      </div>
      <form className={styles.write} onSubmit={handleSend}>
        <TextField
          fullWidth
          placeholder="Say something..."
          autoComplete="off"
          value={txfMessage}
          onChange={(e) => setTxfMessage(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon baseClassName="material-symbols-outlined">sms</Icon>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="send message"
                  onClick={handleSend}
                  edge="end"
                >
                  <Icon
                    baseClassName="material-symbols-outlined"
                    color="secondary"
                    fontSize="large"
                  >
                    send
                  </Icon>
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </form>
    </div>
  );
}

export default React.memo(Chat);
