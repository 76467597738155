import React from 'react';
import { Button, ButtonProps } from '@mui/material';

import styles from './EdButton.module.css';

interface EdButton extends ButtonProps {
  children: React.ReactNode
}

function EdButton(props: EdButton) {
  return (
    <Button className={styles.root} {...props}>
      {props.children}
    </Button>
  );
}

export default React.memo(EdButton);
