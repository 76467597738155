import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Container,
  Grid,
  Snackbar,
  SnackbarCloseReason,
  TextField,
  Typography
} from '@mui/material';

import UserService from '../../services/User';

import styles from './Login.module.css';

function Login() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [openAlert, setOpenAlert] = React.useState(false);
  const [error, setError] = React.useState({
    message: '',
    email: false,
    password: false
  });

  const navigate = useNavigate();

  const verifyInputs = () => {
    if (!email || !password) {
      setError((data) => ({
        ...data,
        email: email === '',
        password: password === ''
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) => {
    e.preventDefault();
    const isValid = verifyInputs();
    if (!isValid) {
      return;
    }
    try {
      await UserService.login(email, password);
      localStorage.setItem('edfluence-email', email);
      navigate('/admin');
    } catch (error) {
      setError({
        message: 'The user or password are incorrect.',
        email: email === '',
        password: password === ''
      });
      setOpenAlert(true);
    }
  };


  const handleCloseAlert = (_event: Event | React.SyntheticEvent<Element, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
    setError((data) => ({
      ...data,
      message: ''
    }));
  };

  return (
    <Container maxWidth={false} disableGutters className={styles.root}>
      <Grid container spacing={1} justifyContent="center" alignContent="center" className={styles.container}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">Login Host</Typography>
        </Grid>
        <Grid item sm={4}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <TextField
              label="E-mail"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={error.email}
              helperText={error.email && 'The E-mail is required'}
            />
            <TextField
              label="Password"
              size="small"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              error={error.password}
              helperText={error.password && 'The Password is required'}
            />
            <Button variant="contained" onClick={handleSubmit} type="submit">Login</Button>
          </form>
        </Grid>
      </Grid>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} elevation={6} severity="error" sx={{ width: '100%' }}>
          {error.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Login;
