import React from 'react';

import { ReactComponent as MicIcon } from '../../img/MicIcon.svg';
import { ReactComponent as RefreshIcon } from '../../img/RefreshIcon.svg';
import { ReactComponent as StopCircleIcon } from '../../img/stop-circle-icon.svg';

import EdFab from '../EdFab';
import EdFeedbackAlert from '../EdFeedbackAlert';
import EdListeningWave from '../EdListeningWave';
import EdProgress from '../EdProgress';
import EdTypography from '../EdTypography';

import * as speechRecognitionService from '../../services/cognitive';

import { LanguageEnum } from '../../Enums/Event';

import styles from './EdSpeakTheSentence.module.css';

interface EdSpeakTheSentenceProps {
  sentence: string;
  onAnswer: any;
  language?: keyof typeof LanguageEnum;
  onClose?: (status: { open: boolean }) => void;
}

const defaultProps = {
  language: 'en-US'
};

function EdSpeakTheSentence({ sentence, onAnswer, language, onClose = () => null }: EdSpeakTheSentenceProps & typeof defaultProps) {
  const [pronunciationScore, setPronunciationScore] = React.useState<number | null>(null);
  const [accuracyScore, setAccuracyScore] = React.useState<number | null>(null);
  const [fluencyScore, setFluencyScore] = React.useState<number | null>(null);
  const [completenessScore, setCompletenessScore] = React.useState<number | null>(null);
  const [listening, setListening] = React.useState(false);
  const [retry, setRetry] = React.useState(false);
  const [isMicrophoneAvailable, setIsMicrophoneAvailable] = React.useState(true);
  const [recognizer, setRecognizer] = React.useState<any>();



  React.useEffect(() => {
    const permissionName = 'microphone' as PermissionName;
    navigator.permissions.query({ name: permissionName }).
      then(e => {
        if (e.state === 'prompt' || e.state === 'denied') {
          navigator.mediaDevices
            .getUserMedia({ audio: true });
        }
        e.onchange = (e: any) => {
          setIsMicrophoneAvailable(e.currentTarget.state === 'granted');
        };
        setIsMicrophoneAvailable(e.state === 'granted');
      });
  }, []);

  const handleOnCloseDialog = () => {
    if (pronunciationScore as number > 75 && !listening) setTimeout(() => onClose({ open: false }), 3000);
  };

  handleOnCloseDialog();

  const handleListen = React.useCallback(() => {
    setListening(true);
    setRetry(true);
    const recognizerInstance = speechRecognitionService.start(LanguageEnum[language], sentence, (response) => {
      setPronunciationScore(response.scores.pronunciationScore);
      setAccuracyScore(response.scores.accuracyScore);
      setFluencyScore(response.scores.fluencyScore);
      setCompletenessScore(response.scores.completenessScore);
      onAnswer({
        answer: JSON.stringify(response.scores),
        is_correct: response.isCorrect
      });
      setListening(false);
    });

    setRecognizer(recognizerInstance);
  }, []);

  const handleListenStop = () => {
    setListening(false);
    setRetry(true);
    recognizer.stop();
  };

  return (
    <div className={styles.root}>
      {pronunciationScore
        ? <section className={styles.score}>
          <EdProgress value={Math.round(pronunciationScore as number)} />
          <div className={styles.scoreBreakdown}>
            <EdProgress value={Math.round(accuracyScore as number)} variant="circular" label="Accuracy" />
            <EdProgress value={Math.round(fluencyScore as number)} variant="circular" color="secondary" label="Fluency" />
            <EdProgress value={Math.round(completenessScore as number)} variant="circular" color="success" label="Completeness" />
          </div>
        </section>
        : <section className={styles.timer}></section>
      }
      <section className={styles.reactive}>
        <EdTypography variant="h5">{sentence}</EdTypography>
      </section>
      {(pronunciationScore === null || !isMicrophoneAvailable) &&
        <section className={styles.feedback}>
          {!isMicrophoneAvailable && <EdFeedbackAlert color="neutral" text="Ready to speak up? Enable your microphone to fully participate in interactive activities." />}
        </section>
      }

      <section className={styles.listeningWaveContainer}>
        {(listening)
          ?
          <EdListeningWave />
          : null}
      </section>

      <section className={styles.actions}>
        {(pronunciationScore as number < 75)
          ? (listening)
            ?
            <StopCircleIcon className={styles.stopCircleIcon} onClick={handleListenStop} />
            : <EdFab
              color={retry ? 'secondary' : 'primary'}
              onClick={handleListen}
              disabled={!isMicrophoneAvailable}
            >
              {retry
                ? <RefreshIcon />
                : <MicIcon />
              }
            </EdFab>
          : null
        }
      </section>
    </div>
  );
}

export default React.memo(EdSpeakTheSentence);
