import React from 'react';
import ClassNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import styles from './EdDialog.module.css';
import EdBadge from '../EdBadge';
import EdButton from '../../components/EdButton';
import { ReactComponent as GoogleIcon } from '../../img/google-icon.svg';
import { SvgIcon } from '@mui/material';


interface IEdDialog {
  children: React.ReactNode;
  title: string;
  icon: string;
  layoutBlur: boolean;
  open: boolean;
  openEdMicrophonePermission: boolean;
  handleTextAlertMicrophonePermission: () => void;
  onClose?: (status: { open: boolean }) => void;
  onSignInWithGoogle: () => void;
}

function EdDialog({ children, title, openEdMicrophonePermission, icon, open, layoutBlur, handleTextAlertMicrophonePermission, onClose = () => null, onSignInWithGoogle = () => null }: IEdDialog) {
  const [openDialog, setOpenDialog] = React.useState(open);

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleOnClose = () => {
    if (openEdMicrophonePermission) {
      return handleTextAlertMicrophonePermission();
    }
    setOpenDialog(value => {
      onClose({ open: !value });
      return !value;
    });
  };

  return (
    <div className={ClassNames(styles.root, {
      [styles.open]: openDialog
    })}>
      <div className={styles.paper}>

        <div className={styles.header}>
          <EdBadge icon={icon} label={title} color="secondary" />
          <div className={styles.closeIconContainer} onClick={handleOnClose}>
            <CloseIcon className={styles.closeIcon} />
          </div>
        </div>
        <div className={styles.content}>
          {children}
          {
            layoutBlur &&
          <div className={styles.layoutBlur}>
            <div className={styles.padlock}>
              <img src='/padlock.png'></img>
            </div>
            <h3>Create your account now for exclusive tracking of your learning progress. 🔑!</h3>
            <EdButton
              variant="outlined"
              fullWidth
              color="secondary"
              size="large"
              sx={{background: 'white' ,'&:hover': { background: 'lightgray'}, marginTop: 20}}
              startIcon={<SvgIcon component={GoogleIcon} />}
              onClick={onSignInWithGoogle}
            >
                Log in with Google
            </EdButton>
          </div>
          }
        </div>
      </div>
    </div>
  );
}

export default React.memo(EdDialog);


