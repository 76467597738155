import React from 'react';
import { Box, Button } from '@mui/material';

import Avatar from '../../../components/Avatar/avatar';

import styles from './WaitingScreen.module.css';

type DoneScreenProps = {
  dataEvent: any;
};

const DoneScreen: React.FC<DoneScreenProps> = (props: DoneScreenProps) => {
  const { dataEvent } = props;
  return (
    <>
      <Box className={styles.container}>
        <Box>
          <Box display="flex" justifyContent="center" mb={1}>
            <Avatar src={dataEvent?.user?.url_image ?? ''} />
          </Box>
          <Box className={styles.title}>{dataEvent?.title} has concluded</Box>
          <Box className={styles.description}>
            If you want to get updates on the upcoming sessions or courses follow the creator.
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            <Button
              color="secondary"
              variant="contained"
              sx={{ borderRadius: '100px', py: 1.5, px: 4, textTransform: 'none', fontWeight: 500 }}
            >
              Follow
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DoneScreen;
