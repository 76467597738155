import React from 'react';
import moment from 'moment';

import EdTypography from '../EdTypography';

import styles from './EdCountdown.module.css';

interface EdCountdown {
  countdownDate: string;
}

function EdCountdown(props: EdCountdown) {
  const [countdownDate] = React.useState(moment(props.countdownDate, 'YYYY-MM-DD[T]HH:mm:ssZs'));
  const [state, setState] = React.useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  React.useEffect(() => {
    setInterval(() => setNewTime(), 1000);
  }, []);

  const setNewTime = () => {
    const now = moment().toISOString();
    if (countdownDate && countdownDate.isAfter(now)) {
      const diffDuration = moment.duration(countdownDate.diff(now));
      const numbersToAddZeroTo = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let days: any = diffDuration.days();
      let hours: any = diffDuration.hours();
      let minutes: any = diffDuration.minutes();
      let seconds: any = diffDuration.seconds();
      days = numbersToAddZeroTo.includes(days) ? `0${days}` : days;
      hours = numbersToAddZeroTo.includes(hours) ? `0${hours}` : hours;
      minutes = numbersToAddZeroTo.includes(minutes) ? `0${minutes}` : minutes;
      seconds = numbersToAddZeroTo.includes(seconds) ? `0${seconds}` : seconds;
      setState({
        days,
        hours,
        minutes,
        seconds
      });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.countdownWrapper}>
        <div className={styles.timeSection}>
          <EdTypography align="center" variant="h2">{state.days}</EdTypography>
          <EdTypography align="center" variant="body2">Days</EdTypography>
        </div>
        <div className={styles.timeSection}>
          <EdTypography align="center" variant="h2">{state.hours}</EdTypography>
          <EdTypography align="center" variant="body2">Hours</EdTypography>
        </div>
        <div className={styles.timeSection}>
          <EdTypography align="center" variant="h2">{state.minutes}</EdTypography>
          <EdTypography align="center" variant="body2">Minutes</EdTypography>
        </div>
        <div className={styles.timeSection}>
          <EdTypography align="center" variant="h2">{state.seconds}</EdTypography>
          <EdTypography align="center" variant="body2">Seconds</EdTypography>
        </div>
      </div>
    </div>
  );
}

export default React.memo(EdCountdown);
