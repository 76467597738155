import React from 'react';

import EdButton from '../EdButton';

import { ReactComponent as MicIcon } from '../../img/MicrophonePermissionIcon.svg';

import EdFeedbackAlert from '../EdFeedbackAlert';

import styles from './EdMicrophonePermissions.module.css';

interface IEdMicrophonePermission {
  handleOnCloseMicrophonePermission?: any;
  textAlert: string;
  isAvaible: boolean;
  setAvaible: any;
}

function EdMicrophonePermission({ handleOnCloseMicrophonePermission, textAlert, isAvaible, setAvaible }: IEdMicrophonePermission) {
  const [text, setText] = React.useState(textAlert);

  const handdlePermission = () => {
    if (!isAvaible) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          setAvaible(true);
          setTimeout(() => {
            handleOnCloseMicrophonePermission();
          }, 3000);
        })
        .catch(() => {
          setText('Please enable microphone permissions in settings.');
          setTimeout(() => {
            setText(textAlert);
          }, 2000);
        });
    }
  };

  React.useEffect(() => {
    if (isAvaible) {
      setText('Mic enabled! Get talking and enjoy interactive activities. Have fun!');
      setTimeout(() => {
        handleOnCloseMicrophonePermission();
      }, 3000);
    }
  }, [isAvaible]);

  React.useEffect(()=>{
    setText(textAlert);
  },[textAlert]);

  return (
    <div className={styles.root}>
      <EdFeedbackAlert
        text={text}
        color={isAvaible ? 'success' : 'info'}
      />
      {!isAvaible ? <EdButton
        variant="contained"
        fullWidth
        color="primary"
        size="large"
        startIcon={<MicIcon />}
        onClick={handdlePermission}
      >
        Enable Microphone
      </EdButton> : null}
    </div>
  );
}

export default React.memo(EdMicrophonePermission);
