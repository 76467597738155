import env from './env';

const endpoints = {
    user: {
        index: `${env.api.security.basePath}/user`,
        login: `${env.api.security.basePath}/login`,
        channel: `${env.api.security.basePath}/user/channel`
    },
    events: {
        index: `${env.api.events.basePath}/event`,
        user: `${env.api.events.basePath}/event/user`
    },
    report: {
      index: `${env.api.events.basePath}/report/event`,
    },
    lesson: {
      index: `${env.api.lessons.basePath}/lesson`,
    }
}

export default endpoints;
