import axios from 'axios';

import config from '../config';

class ReportService {
  static async create(eventId: number|string|undefined, email: string, uuid: string|null) {
    try {
      const response = await axios({
        url: `${config.endpoints.report.index}/${eventId}`,
        method: 'POST',
        data: {
          email,
          uuid
        }
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }
}

export default ReportService;
