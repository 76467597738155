import React from 'react';
import { Container, Box, Icon, IconButton } from '@mui/material';
import AgoraUIKit, { layout } from 'agora-react-uikit';
import { useNavigate, useParams } from 'react-router-dom';
import 'agora-react-uikit/dist/index.css';

import config from '../../config';
import EventService from '../../services/Events';
import { SocketContext } from '../../context/socketContext';
import { UsersContext } from '../../context/usersContext';

import EdDialog from '../../components/Dialog';
import Chat from '../../components/Chat';
import InteractionsDialog from './InteractionsDialog';

// Icons
import Mic from '../../img/agora_icons/mic.svg';
import MicOff from '../../img/agora_icons/mic_off.svg';
import VideoCam from '../../img/agora_icons/videocam.svg';
import VideoCamOff from '../../img/agora_icons/videocam_off.svg';
import Interaction from '../../img/interaction.svg';
import ChatIcon from '../../img/chatIcon.png';

import styles from './HostLiveStreaming.module.css';
import CloseSessionDialog from './CloseSessionDialog';

function HostLiveStreaming() {
  const socketHostUuid = localStorage.getItem('ed-socket-host-uuid');

  const [email] = React.useState(localStorage.getItem('edfluence-email') || '');
  const [userName] = React.useState(localStorage.getItem('edfluence-username'));
  const [userImage] = React.useState(localStorage.getItem('edfluence-user-image'));
  const [dataEvent, setDataEvent] = React.useState<any>({});
  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
  const [isCloseSessionDialogOpen, setIsCloseSessionDialogOpen] = React.useState<boolean>(false);
  const [commentsCount, setCommentsCount] = React.useState<number>(0);
  const [comments, setComments] = React.useState<any[]>([]);
  const [openChat, setOpenChat] = React.useState(false);
  const [answerFiltered, setAnswersFiltered] = React.useState<any[]>([]);

  const navigate = useNavigate();
  const socket = React.useContext(SocketContext);
  const { users, setUsers } = React.useContext(UsersContext) as any;
  const { id } = useParams();

  React.useEffect((): any => {
    async function getEventData() {
      const requestEvent = await EventService.getEventById(id);
      setDataEvent(requestEvent.data);
    }
    getEventData();
  }, [id]);

  React.useEffect(() => {
    socket.emit(
      'login',
      { email: email, name: userName, image: userImage, room: id, role: 'teacher', userId: socketHostUuid },
      (error: any) => {
        if (error) {
          console.log(error);
        }
      }
    );
    socket.emit('statusEvent', { room: id, status: 'IN_PROGRESS' });
    EventService.editEvent(id, { status: 'IN_PROGRESS', started_date: new Date().toISOString() });
  }, []);

  React.useEffect(() => {
    socket.on('users', (data: any) => {
      setUsers(data);
    });
    socket.on('newMessage', (data: any) => {
      const exist = comments?.find(comment =>
        comment.date === data.date && comment.message === data.message && comment.email === data.email
      );
      if (!exist) {
        if (openChat === false) {
          setCommentsCount(() => commentsCount + 1);
        }
        setComments(() => [...comments, data]);
      }
    });
  }, [comments, commentsCount, id, openChat, setUsers, socket]);

  React.useEffect(() => {
    socket.on('questionAnswerd', async () => {
      const eventAnswers = await EventService.getEventAnswers(id);
      const { data } = eventAnswers;
      const filtered = data.reduce((accumulator: any, current: any) => {
        const ordered = accumulator;
        const indexQuestion = ordered
          .findIndex((element: any) => element.id === current.question.id);
        if (indexQuestion === -1) {
          ordered.push({
            id: current.question.id,
            type: current.question.type,
            answers: [current]
          });
        } else {
          const indexUserAnswer = ordered[indexQuestion].answers
            .findIndex((element: any) => element.user_email === current.user_email);
          if (indexUserAnswer === -1) {
            ordered[indexQuestion].answers.push(current);
          }
          if (indexUserAnswer !== -1 && ordered[indexQuestion].answers[indexUserAnswer].answerd_date < current.answerd_date) {
            ordered[indexQuestion].answers[indexUserAnswer] = current;
          }
        }
        return ordered;
      }, []);
      setAnswersFiltered(filtered);
    });
  }, [socket]);

  const handleToggleSession = (status: 'SCHEDULED' | 'DONE') => {
    socket.emit('logout', socketHostUuid);
    socket.emit('statusEvent', { room: id, status });
    EventService.editEvent(id, { status });
    navigate('/', { replace: true });
  };

  const handleEndCall = () => {
    setIsCloseSessionDialogOpen(true);
  };

  const handleLaunchQuestion = (question: any) => {
    socket.emit('launchQuestion', question, id);
  };

  const handleToggleOpenDialog = React.useCallback(() => {
    setIsOpenDialog((prevValue: boolean) => !prevValue);
  }, []);

  const handleSendMessage = React.useCallback((message: string) => {
    socket.emit('sendMessage', { userId: socketHostUuid, room: id, message });
  }, [id, socket, socketHostUuid]);

  return (
    <>
      <Container maxWidth={false} disableGutters className={styles.root}>
        {isOpenDialog && (
          <InteractionsDialog
            open={true}
            answers={answerFiltered}
            interactions={dataEvent?.questions}
            onLaunchInteraction={handleLaunchQuestion}
            onClose={handleToggleOpenDialog}
            activeUsers={users.length}
          />
        )}
        <EdDialog
          id="comments-dialog"
          icon="forum"
          onClose={() => setOpenChat(false)}
          open={openChat}
          title="Comments"
        >
          <Chat messages={comments} onSend={handleSendMessage} />
        </EdDialog>
        <div className={styles.videoContainer}>
          {dataEvent?.channel_name && (
            <AgoraUIKit
              rtcProps={{
                appId: config.agora.appId,
                channel: dataEvent.channel_name,
                token: dataEvent.streaming_token, //add your token if using app in secured mode
                role: 'host',
                layout: layout.grid,
              }}
              rtmProps={{ username: email || 'user', displayUsername: false }}
              callbacks={{
                EndCall: handleToggleOpenDialog,
              }}
              styleProps={{
                UIKitContainer: {
                  position: 'relative',
                  background: 'transparent',
                },
                localBtnContainer: {
                  background: 'transparent',
                  position: 'absolute',
                  bottom: '0',
                },
                localBtnStyles: {
                  endCall: {},
                },
                customIcon: {
                  mic: Mic,
                  micOff: MicOff,
                  videocam: VideoCam,
                  videocamOff: VideoCamOff,
                  callEnd: Interaction,
                },
              }}
            />
          )}
        </div>
        {/* Back button and Users  */}
        <Box
          sx={{ position: 'absolute', top: 20, color: 'white' }}
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <IconButton onClick={handleEndCall}>
              <Icon sx={{ color: 'white' }}>keyboard_backspace</Icon>
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" fontFamily="Inter">
            <Icon sx={{ mr: 1 }}>account_box_outlined</Icon>
            {users.length} viewers
          </Box>
          <Box></Box>
        </Box>
        <div className={styles.chatIconContainer} onClick={() => { setOpenChat(true); setCommentsCount(0); }}>
          <img className={styles.icon} src={ChatIcon} alt="chat" />
          <span>{commentsCount}</span>
        </div>
      </Container>
      {isCloseSessionDialogOpen && (
        <CloseSessionDialog
          onClose={() => {
            setIsCloseSessionDialogOpen(false);
          }}
          onEndLive={() => {
            handleToggleSession('DONE');
          }}
          onReScheduleLive={() => {
            handleToggleSession('SCHEDULED');
          }}
        />
      )}
    </>
  );
}

export default React.memo(HostLiveStreaming);
