import React from 'react';
import { Chip, Dialog, DialogContent, Box, List } from '@mui/material';
import { DialogActions, Icon, Button } from '@mui/material';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import styles from './paper.module.css';
import DialogTitle from '../../components/Dialog/DialogTitle';

type ListInteractionsDialogProps = {
  open: boolean;
  interactions?: any[];
  onLaunchInteraction: (interaction: any) => void;
  onClose: () => void;
};

const ListInteractionsDialog: React.FC<ListInteractionsDialogProps> = (
  props: ListInteractionsDialogProps
) => {
  const {
    interactions,
    onLaunchInteraction,
    onClose,
  }: ListInteractionsDialogProps = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog
        open
        classes={{
          paper: fullScreen ? styles.dialog : styles.webDialog,
        }}
      >
        <DialogTitle id="interactions-dialog-title" onClose={onClose}>
          <Chip
            label="Interaction launcher"
            sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
          ></Chip>
        </DialogTitle>
        <DialogContent>
          <List>
            {interactions?.map(
              (question: any, key: React.Key | null | undefined) => (
                <ListItem key={key} disablePadding>
                  <ListItemButton
                    sx={{ px: 1 }}
                    disableGutters
                    onClick={() => {
                      onLaunchInteraction({ id: key, ...question });
                    }}
                  >
                    <Box width="100%">
                      <ListItemText
                        primary={question?.sentence ?? 'Interaction title/name'}
                      />
                      {/* <Chip label={question?.type ?? ''}></Chip> */}
                    </Box>
                    <Icon>launch_outlined</Icon>
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>add_circle_outline_outlined</Icon>}
            sx={{
              textTransform: 'none',
              borderRadius: '100px',
              background: '#DD2590',
              '&:hover': {
                bgcolor: '#DD2590',
              },
            }}
          >
            Create interaction
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(ListInteractionsDialog);
