import React from 'react';
import { Fab, FabProps } from '@mui/material';

import styles from './EdFab.module.css';

interface EdFab extends FabProps {
  children: React.ReactNode
}

function EdFab(props: EdFab) {
  return (
    <Fab className={styles.root} {...props}>
      {props.children}
    </Fab>
  );
}

export default React.memo(EdFab);
