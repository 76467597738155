import React from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import DialogTitle from './DialogTitle';

import styles from './Dialog.module.css';
import EdBadge from '../EdBadge';

type DialogProps = {
  id: string;
  children?: React.ReactNode;
  title: string;
  open: boolean;
  icon?: string;
  onClose: () => void;
}

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

function EdDialog({ id, icon, open, onClose, title, children }: DialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      open={open}
      classes={{
        paper: fullScreen ? styles.root : styles.rootWeb,
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle id={`${id}-title`} onClose={onClose}>
        <EdBadge icon={icon} label={title} color="secondary" />
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(EdDialog);
