import React from 'react';
import { SvgIcon } from '@mui/material';

import EdButton from '../../../components/EdButton';

import { ReactComponent as GoogleIcon } from '../../../img/google-icon.svg';

import EdFeedbackAlert from '../../../components/EdFeedbackAlert';

import styles from './LoginForm.module.css';

interface LoginForm {
  onSignInWithGoogle: () => void
}

function LoginForm({ onSignInWithGoogle = () => null }: LoginForm) {
  return (
    <div className={styles.root}>
      <EdFeedbackAlert
        text="Log in to enjoy an interactive experience that'll blow your mind! Don't miss out!"
        color="neutral"
      />
      <EdButton
        variant="outlined"
        fullWidth
        color="secondary"
        size="large"
        startIcon={<SvgIcon component={GoogleIcon} />}
        onClick={onSignInWithGoogle}
      >
        Log in with Google
      </EdButton>
    </div>
  );
}

export default React.memo(LoginForm);
