import axios from 'axios';

import config from '../config';

class EventService {
  static async getEventAnswers(eventId: any) {
    try {
      const response = await axios({
        url: `${config.endpoints.events.index}/${eventId}/answer`,
        method: 'GET'
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async getUserEvents() {
    try {
      const token = localStorage.getItem('ispeak-token');
      const response = await axios({
        url: config.endpoints.events.user,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async getEventById(id: any) {
    try {
      const response = await axios({
        url: `${config.endpoints.events.index}/${id}`,
        method: 'GET'
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async createEvent(eventData: EventData) {
    try {
      const token = localStorage.getItem('ispeak-token');
      const response = await axios({
        url: `${config.endpoints.events.index}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: eventData
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async editEvent(id: any, eventData: EventData) {
    try {
      const token = localStorage.getItem('ispeak-token');
      const response = await axios({
        url: `${config.endpoints.events.index}/${id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: eventData
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async createEventAnswer(data: AnserData ) {
    try {
      const token = localStorage.getItem('ispeak-token');
      const response = await axios({
        url: `${config.endpoints.events.index}/${data.event_id}/answer`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }
}

export default EventService;