import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AvatarGenerator } from 'random-avatar-generator';

import Admin from './Pages/Admin';
import HostLiveStreaming from './Pages/HostLiveStreaming';
import Login from './Pages/Login';
import LiveSteaming from './Pages/LiveSteaming';
import NotFound from './Pages/NotFound';
import Lesson from './Pages/Lesson';

import UserService from './services/User';

import './styles/App.css';

const generator = new AvatarGenerator();

function RedirectAdmin({ page }: any) {
  const navigate = useNavigate();
  const token = localStorage.getItem('ispeak-token');
  if (token && (token !== 'undefined')) {
    UserService.me()
      .then(response => {
        localStorage.setItem('edfluence-username', response.data?.user_name || 'Teacher');
        localStorage.setItem('edfluence-user-image', response.data?.detail?.url_picture || generator.generateRandomAvatar());
      });
    return page;
  }
  React.useEffect(() => {
    navigate('/login', { replace: true });
  });
  return null;
}

function RedirectLogin() {
  const navigate = useNavigate();
  const token = localStorage.getItem('ispeak-token');
  if (!token || (token === 'undefined')) {
    return <Login />;
  }
  React.useEffect(() => {
    navigate('/admin');
  });
  return null;
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<RedirectAdmin page={<Admin />} />} />
      <Route path='/admin' element={<RedirectAdmin page={<Admin />} />} />
      <Route path="/admin/event/:id" element={<RedirectAdmin page={<HostLiveStreaming />} />} />
      <Route path="/event/:id" element={<LiveSteaming />} />
      <Route path="/login" element={<RedirectLogin />} />
      <Route path="/lesson/:id" element={<Lesson />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
