import React from 'react';
import { Chip, DialogContent, Typography } from '@mui/material';
import { DialogActions, Icon, Button } from '@mui/material';
import styles from '../paper.module.css';
import DialogTitle from '../../../components/Dialog/DialogTitle';

type EndDialogProps = {
  onClose: () => void;
  onEndLive: () => void;
};

const EndDialog: React.FC<EndDialogProps> = (props: EndDialogProps) => {
  const { onClose, onEndLive }: EndDialogProps = props;
  return (
    <>
      <DialogTitle id="interactions-dialog-title" onClose={onClose}>
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
              warning_amber
            </Icon>
          }
          label="Warning"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        ></Chip>
      </DialogTitle>
      <DialogContent>
        <Typography className={styles.title}>The audience will be disconnected.</Typography>
        <Typography className={styles.title}>Are you sure you want to end the live session?</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none',
            borderRadius: '100px',
            background: '#DD2590',
            '&:hover': {
              bgcolor: '#DD2590'
            }
          }}
          onClick={onEndLive}
        >
          End Live Session
        </Button>
      </DialogActions>
    </>
  );
};

export default EndDialog;
