import React from 'react';
import MaterialDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type DialogTitleProps = {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
};

const DialogTitle: React.FC<DialogTitleProps> = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <>
      <MaterialDialogTitle
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        {...other}
      >
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MaterialDialogTitle>
    </>
  );
};

export default DialogTitle;
