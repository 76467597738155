import endpoints from './endpoints';
import env from './env';
const config = {
    ...env,
    endpoints,
    agora: {
        appId: process.env.REACT_APP_AGORA_APP_ID || '',
        certificate: process.env.REACT_APP_AGORA_APP_CERTIFICATE || ''
    },
    azure: {
      speechService: {
        region: process.env.REACT_APP_AZURE_SPEECH_REGION || null,
        key: process.env.REACT_APP_AZURE_SPEECH_KEY || null
      }
    }
}

export default config;
