import { Box } from '@mui/material';
import Avatar from '../../../components/Avatar/avatar';
import React from 'react';

import styles from './WaitingScreen.module.css';
import EdCountdown from '../../../components/EdCountdown';

type WaitingScreenProps = {
  dataEvent: any;
};

const WaitingScreen: React.FC<WaitingScreenProps> = (props: WaitingScreenProps) => {
  const { dataEvent } = props;

  return (
    <>
      <Box className={styles.container}>
        <Box>
          <Box display="flex" justifyContent="center" mb={1}>
            <Avatar src={dataEvent?.user?.url_image ?? ''} />
          </Box>
          <Box className={styles.title}>{dataEvent?.title} will start soon</Box>
          <Box className={styles.description}>{dataEvent?.description}</Box>
          <Box mt={2}>
            <EdCountdown countdownDate={dataEvent?.start_date}/>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WaitingScreen;
