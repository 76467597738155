import classNames from 'classnames';
import React from 'react';

import styles from './EdFeedbackAlert.module.css';

interface EdFeedbackAlert {
  color?: 'neutral' | 'error' | 'success' | 'warning' | 'info';
  text: string;
}

function EdFeedbackAlert({ color = 'success', text }: EdFeedbackAlert) {
  const classes = classNames(styles.root, {
    [styles[color]]: true
  });
  return (
    <div className={classes}>
      <span className={`${styles.icon} material-symbols-outlined`} />
      <div className={styles.text}>{text}</div>
    </div>
  );
}

export default React.memo(EdFeedbackAlert);
