import React from 'react';
import { List, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { ReactComponent as RefreshIcon } from '../../img/RefreshIcon.svg';
import { ReactComponent as CheckIcon } from '../../img/check_without_circle.svg';
import EastIcon from '@mui/icons-material/East';

import EdButton from '../EdButton';
import EdFeedbackAlert from '../EdFeedbackAlert';

import styles from './MultipleChoice.module.css';

interface MultimpleChoiceProps {
  sentence: string;
  acceptedAnswers: string[];
  distractors: string[];
  onContinue: any;
  onAnswer: any;
  poll?: boolean
}

function MultipleChoice({ sentence, acceptedAnswers, distractors, onAnswer, onContinue, poll }: MultimpleChoiceProps) {
  const [answer, setAnswer] = React.useState('');
  const [result, setResult] = React.useState<'success' | 'fail' | undefined>(undefined);
  const [done, setDone] = React.useState<boolean | null>(null);

  const handleCheck = () => {
    const isDone = acceptedAnswers.includes(answer);
    setDone(isDone);
    onAnswer({
      answer,
      is_correct: isDone
    });
    setResult(isDone ? 'success' : 'fail');
    if (isDone) {
      setTimeout(() => {
        onContinue();
      }, 3000);
    }
  };

  const handleTryAgain = () => {
    setDone(null);
    setResult(undefined);
    setAnswer('');
  };

  const answers = React.useMemo(() => [...acceptedAnswers, ...distractors].sort(), [acceptedAnswers, distractors]);
  return (
    <div className={styles.root}>
      <div className={styles.sentence}>{sentence}</div>
      <List sx={{ pt: '8px', pb: 0, pr: '24px', pl: '24px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
        {answers.map((element, key) => (
          <ListItemButton
            selected={element === answer}
            disabled={(result) && !(element === answer)}
            className={
              element === answer && result === 'success'
                ? styles.list_item_success
                : element === answer && result === 'fail'
                  ? styles.list_item_fail
                  : styles.default
            }
            sx={{
              border: '0.5px solid #C3C6CF',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              borderRadius: '5px',
              pt: '5px',
              pb: '5px',
              '&.Mui-selected':
                element === answer && result === undefined
                  ? {
                    backgroundColor: '#DEE0FF !important',
                    color: '#00105A !important',
                    border: '3px solid #F0EFFF'
                  }
                  : {},
              '&.Mui-selected .MuiListItemIcon-root':
                element === answer && result === undefined
                  ? {
                    color: '#001D32 !important'
                  }
                  : {}
            }}
            key={key}
            onClick={() => {
              if (result) return;
              setAnswer(element);
              setResult(undefined);
            }}
          >
            <ListItemIcon
              className={
                element === answer && result === 'success'
                  ? styles.list_item_icon_success
                  : element === answer && result === 'fail'
                    ? styles.list_item_icon_fail
                    : styles.default
              }
              sx={{ minWidth: '20px', color: '#73777F' }}><FiberManualRecordIcon sx={{ fontSize: '12px' }} /></ListItemIcon>
            <ListItemText primary={element} />
          </ListItemButton>
        ))}
      </List>
      <div className={styles.result}>
        {done === true && (
          <EdFeedbackAlert
            color="success"
            text={poll ? 'Your vote was sent.' : 'Nailed it, keep up the good work.'}
          />
        )}
        {done === false && (
          <EdFeedbackAlert
            color="warning"
            text={poll ? 'You have to choose one.' : 'Oops, we need to practice a bit more.'}
          />
        )}
      </div>
      <div className={styles.actionsContainer}>
        {answer && !result && (
          <EdButton variant="contained" onClick={handleCheck}>
            {poll
              ? <>Send vote</>
              : <>Check <CheckIcon className={styles.checkIcon} /></>
            }
          </EdButton>
        )}
        {result === 'fail' && (
          <EdButton startIcon={<RefreshIcon width="20px" height="20px" />} variant="contained" color="secondary" onClick={handleTryAgain}>
            Try again
          </EdButton>
        )}
        {result === 'success' && (
          <EdButton variant="contained" color="primary" onClick={onContinue}>
            <>Continue <EastIcon fontSize="small" className={styles.eastIcon} /></>
          </EdButton>
        )}
      </div>
    </div>
  );
}

export default React.memo(MultipleChoice);
