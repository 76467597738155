import React from 'react';
import classNames from 'classnames';

import styles from './EdBadge.module.css';

interface EdBadge {
  label: string;
  icon?: string;
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'success'
}

function EdBadge({ label, icon, color = 'default' }: EdBadge) {
  return (
    <div className={classNames(styles.root, { [styles[color]]: !!color })}>
      {icon && (
        <span className={`material-icons ${styles.icon}`}>{icon}</span>
      )}
      <span className={styles.label}>{label}</span>
    </div>
  );
}

export default React.memo(EdBadge);
