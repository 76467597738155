import React from 'react';
import classNames from 'classnames';
import {
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  LinearProgress,
  LinearProgressProps
} from '@mui/material';

import { ReactComponent as SmileyFaceIcon } from '../../img/smiley-face-icon.svg';
import { ReactComponent as NeutralFaceIcon } from '../../img/neutral-face-icon.svg';
import { ReactComponent as WoefulFaceIcon } from '../../img/woeful-face-icon.svg';

import styles from './EdProgress.module.css';

interface EdProgressProps {
  variant: 'half' | 'circular' | 'linear';
  value: number;
  color?: CircularProgressProps['color'];
  label?: string;
  percentage?: number;
}

const defaultProps = {
  variant: 'half',
  value: 0
};

function SemiCircle({ value }: { value: number }) {
  let color = 'bad';
  if (value >= 55 && value < 75) {
    color = 'neutral';
  }
  if (value >= 75) {
    color = 'good';
  }
  const semiCircleClasses = classNames({
    [styles[color]]: true
  });
  const offset = (value * 190 / 100) + 190;
  return (
    <div className={semiCircleClasses}>
      <svg width="131" height="72" viewBox="0 0 131 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M124.454 65.454C124.454 57.718 122.929 50.0577 119.967 42.9105C117.004 35.7633 112.661 29.2692 107.187 23.799C101.712 18.3288 95.2133 13.9896 88.0606 11.0291C80.9079 8.06865 73.2417 6.54492 65.4997 6.54492C57.7577 6.54492 50.0915 8.06864 42.9388 11.0291C35.7861 13.9896 29.287 18.3288 23.8126 23.799C18.3381 29.2692 13.9956 35.7633 11.0328 42.9105C8.07008 50.0576 6.54517 57.7179 6.54517 65.454" stroke="#EAECF0" strokeWidth="13.0909" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M124.454 65.454C124.454 57.718 122.929 50.0577 119.967 42.9105C117.004 35.7633 112.661 29.2692 107.187 23.799C101.712 18.3288 95.2133 13.9896 88.0606 11.0291C80.9079 8.06865 73.2417 6.54492 65.4997 6.54492C57.7577 6.54492 50.0915 8.06864 42.9388 11.0291C35.7861 13.9896 29.287 18.3288 23.8126 23.799C18.3381 29.2692 13.9956 35.7633 11.0328 42.9105C8.07008 50.0576 6.54517 57.7179 6.54517 65.454" strokeWidth="13.0909" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="190" strokeDashoffset={offset} />
      </svg>
      <span className={styles.text}>{value}%</span>
    </div>
  );
}

function Circular({ value, color = 'primary', label }: { value: number, color: CircularProgressProps['color'], label?: string }) {
  return (
    <>
      <div className={styles.circleWrap}>
        <CircularProgress
          size={58}
          value={value}
          color={color}
          variant="determinate"
          sx={{
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
        />
        <CircularProgress
          size={58}
          value={100}
          variant="determinate"
        />
        <span className={classNames(styles.text, { [styles[color]]: true })}>
          {value}%
        </span>
      </div>
      <span className={classNames(styles.label, { [styles[color]]: true })}>
        {label}
      </span>
    </>
  );
}

function Linear({ value, color = 'success', label , percentage }: { value: number, color: LinearProgressProps['color'], label?: string, percentage: number }) {
  const textClasses = classNames(styles.text, {
    [styles.good]: color === 'success',
    [styles.neutral]: color === 'info',
    [styles.bad]: color === 'error'
  });
  const iconClasses = classNames(styles.icon, {
    [styles.good]: color === 'success',
    [styles.neutral]: color === 'info',
    [styles.bad]: color === 'error'
  });
  return (
    <>
      {(color === 'success' || color === 'info' || color === 'error') &&
        < div className={iconClasses}>
          {color === 'success' && <SmileyFaceIcon />}
          {color === 'info' && <NeutralFaceIcon />}
          {color === 'error' && <WoefulFaceIcon />}
        </div>
      }
      <div className={styles.wrap}>
        <div className={textClasses}>
          <span>{label}</span>
          <span>{value}</span>
        </div>
        <LinearProgress variant="determinate" value={percentage} color={color} />
      </div>
    </>
  );
}

function EdProgress(props: EdProgressProps & typeof defaultProps) {
  const classes = classNames(styles.root, {
    [styles[props.variant]]: true
  });
  return (
    <div className={classes}>
      {props.variant === 'half' &&
        <SemiCircle value={props.value} />
      }
      {props.variant === 'circular' &&
        <Circular value={props.value} color={props.color} label={props.label} />
      }
      {props.variant === 'linear' &&
        <Linear value={props.value} color={props.color} label={props.label} percentage={props.percentage || 0} />
      }
    </div>
  );
}

EdProgress.defaultProps = defaultProps;

export default React.memo(EdProgress);
