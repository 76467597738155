import React from 'react';

import ListInteractionsDialog from './ListInteractionsDialog';
import ShowInteractionDialog from './ShowInteractionDialog';
import InteractionAnalytics from './InteractionAnalytics';

type InteractionsDialogProps = {
  activeUsers: number;
  open: boolean;
  interactions?: any[];
  answers: any[];
  onLaunchInteraction: (interaction: any) => void;
  onClose: () => void;
};

const InteractionsDialog: React.FC<InteractionsDialogProps> = ({
  activeUsers,
  open,
  interactions,
  onLaunchInteraction,
  onClose,
  answers
}: InteractionsDialogProps) => {
  const [selectedInteraction, setSelectedInteraction] = React.useState<any>(undefined);
  const [interactionLaunched, setInteractionLaunched] = React.useState(false);
  const [lastQuestionLaunched, setLastQuestionLaunched] = React.useState<any>(null);

  const handleOnSelectInteraction = React.useCallback((interaction: any) => {
    setSelectedInteraction(interaction);
  }, []);

  const handleLaunchInteraction = React.useCallback((interaction: any) => {
    setInteractionLaunched(true);
    onLaunchInteraction(interaction);
    setLastQuestionLaunched(interaction);
  }, []);

  return (
    <>
      {open && selectedInteraction === undefined && (
        <ListInteractionsDialog
          open={open}
          interactions={interactions}
          onLaunchInteraction={handleOnSelectInteraction}
          onClose={onClose}
        />
      )}
      {open && selectedInteraction !== undefined && !interactionLaunched && (
        <ShowInteractionDialog
          onLaunchInteraction={handleLaunchInteraction}
          onClose={onClose}
          interaction={selectedInteraction}
        />
      )}
      {open && interactionLaunched &&
        <InteractionAnalytics
          onLaunchInteraction={handleLaunchInteraction}
          activeUsers={activeUsers}
          question={lastQuestionLaunched}
          answers={answers}
          onClose={onClose}
        />
      }
    </>
  );
};

export default InteractionsDialog;
