import axios from 'axios';

import config from '../config';

class UserService {
  static async login(email: string, password: string) {
    try {
      const response = await axios({
        url: config.endpoints.user.login,
        method: 'POST',
        data: {
          email,
          password,
          strategy: 'LOCAL'
        }
      });
      localStorage.setItem('ispeak-token', response.data.token);
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async signin(token: string) {
    try {
      const response = await axios({
        url: `${config.endpoints.user.index}/signin`,
        method: 'GET',
        headers: {
          FirebaseAuthToken: token
        }
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async getUserChannels() {
    try {
      const token = localStorage.getItem('ispeak-token');
      const response = await axios({
        url: config.endpoints.user.channel,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async getUserDetail(uuid: string) {
    try {
      const response = await axios({
        url: `${config.endpoints.user.index}/${uuid}`,
        method: 'GET'
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async me() {
    try {
      const token = localStorage.getItem('ispeak-token');
      const response = await axios({
        url: `${config.endpoints.user.index}/me`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async getUserByToken(token: any) {
    try {
      const response = await axios({
        url: `${config.endpoints.user.index}/me`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }
}

export default UserService;
