import React from 'react';
import { Container, Typography } from '@mui/material';

import './NotFound.css';

function NotFound() {
  return (
    <Container className='not-found-root'>
      <Typography variant="h1" align="center">404</Typography>
      <Typography variant="h2" align="center">Not Found</Typography>
    </Container>
  );
}

export default NotFound;