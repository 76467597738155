import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import AgoraUIKit, { layout } from 'agora-react-uikit';
import 'agora-react-uikit/dist/index.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
  SvgIcon
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import ChatIcon from '../../img/chatIcon.png';

import {
  auth,
  logOut,
  sendLinkToEmail,
  signInWithEmail,
  signInWithGoogle,
  verifySignInWithEmailLink
} from '../../services/firebase';

import config from '../../config';

import { SocketContext } from '../../context/socketContext';
import EventService from '../../services/Events';
import UserService from '../../services/User';
import ReportService from '../../services/Report';

import EdTypography from '../../components/EdTypography';
import Chat from '../../components/Chat';
import EdDialog from '../../components/Dialog';
import TypeTheWords from '../../components/TypeTheWords';
import MultipleChoice from '../../components/MultipleChoice';
import Cta from '../../components/Cta';
import EdSpeakTheSentence from '../../components/EdSpeakTheSentence';

import { ReactComponent as GoogleIcon } from '../../img/google-icon.svg';

import WaitingScreen from './screens/WaitingScreen';
import DoneScreen from './screens/DoneScreen';

import { QuestionType } from '../../Enums/Event';

import styles from './LiveSteaming.module.css';

function LiveSteaming() {
  const socketAudienceUuid = localStorage.getItem('ed-socket-audience-uuid');

  const [userImage] = React.useState(localStorage.getItem('edfluence-audience-image'));
  const [email, setEmail] = React.useState('');
  const [liveStreamStatus, setLiveStreamStatus] = React.useState<
    'LOADING' | 'INIT' | 'SCHEDULED' | 'DONE' | 'IN_PROGRESS' | 'CANCELLED'
  >('LOADING');
  const [dataEvent, setDataEvent] = React.useState<any>({});
  const [emailLinkSent, setEmailLinkSent] = React.useState<boolean>(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorFormatEmail, setErrorFormatEmail] = React.useState(false);
  const [question, setQuestion] = React.useState<any>(null);
  const [openQuestion, setOpenQuestion] = React.useState(false);
  const [commentsCount, setCommentsCount] = React.useState<number>(0);
  const [comments, setComments] = React.useState<any[]>([]);
  const [openChat, setOpenChat] = React.useState(false);
  const [questionTitle, setQuestionTitle] = React.useState<any>({});

  const socket = React.useContext(SocketContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, loading] = useAuthState(auth);

  React.useEffect(() => {
    if (verifySignInWithEmailLink(window.location.href)) {
      signInWithEmail(window.location.href)
        .then(() => {
          navigate(`/event/${id}`);
        });
    }
  }, []);

  React.useEffect(() => {
    socket.on('question', (question: any) => {
      setQuestion(question);
      const title: any = {
        [QuestionType.SpeakTheSentence]: { icon: 'microphone', title: 'Speaking practice' },
        [QuestionType.TypeTheWords]: { icon: 'text_fields', title: 'Type the words' },
        [QuestionType.MultipleChoice]: { icon: 'format_list_bulleted', title: 'Select the answer' },
        [QuestionType.CTA]: { icon: 'rocket_launch', title: question.sub_header }
      };
      setQuestionTitle(title[question.type]);
      setOpenQuestion(true);
    });
  }, [socket, setQuestion, setOpenQuestion]);

  React.useEffect((): any => {
    async function getEventData() {
      const requestEvent = await EventService.getEventById(id);
      const requestUser = await UserService.getUserDetail(requestEvent.data.user_id);
      const data = {
        ...requestEvent.data,
        user: {
          url_image: requestUser?.data?.detail?.url_picture || null
        }
      };
      setDataEvent(data);
      if (user) {
        socket.emit('login', {
          email: user.email,
          name: user.displayName,
          image: user.photoURL || userImage,
          room: id,
          userId: socketAudienceUuid
        }, (error: any) => {
          if (error) {
            console.log(error);
          }
          ReportService.create(id, user.email || 'guest', socketAudienceUuid);
        });
      }
      setLiveStreamStatus(data.status);
    }
    getEventData();
  }, [id, user]);

  React.useEffect(() => {
    socket.on('statusEvent', (data: any) => {
      setLiveStreamStatus(data?.status);
    });
  }, [socket]);

  React.useEffect(() => {
    socket.on('newMessage', (data: any) => {
      const exist = comments?.find(comment =>
        comment.date === data.date && comment.message === data.message && comment.email === data.email
      );
      if (!exist) {
        if (openChat === false) {
          setCommentsCount(() => commentsCount + 1);
        }
        setComments(() => [...comments, data]);
      }
    });
  }, [comments, commentsCount, openChat, socket]);

  const handleOnCloseDialog = (_: any, reason: string) => {
    if (reason === 'backdropClick') {
      return null;
    }
    setOpenQuestion(false);
  };

  const handleEndCall = () => {
    socket.emit('logout', socketAudienceUuid);
    logOut();
  };

  const handleOnAnswer = async (data: any) => {
    const answerData = {
      ...data,
      event_id: Number(id),
      user_email: user?.email
    };
    await EventService.createEventAnswer(answerData);
    socket.emit('answerQuestion', answerData, socketAudienceUuid);
  };

  const handleSendMessage = React.useCallback((message: string) => {
    socket.emit('sendMessage', { userId: socketAudienceUuid, room: id, message });
  }, [id, socket, socketAudienceUuid]);

  const onChangeEmail = (event: any) => {
    const { value } = event.target;
    setErrorFormatEmail(!event?.target?.validity?.valid);
    setEmail(value);
  };

  const handleSendEmailLink = React.useCallback((event: React.FormEvent<HTMLButtonElement | HTMLFormElement>) => {
    event.preventDefault();
    setErrorEmail(email === '');
    console.log(email);
    if (!email || errorFormatEmail) {
      return;
    }
    sendLinkToEmail(email);
    setEmailLinkSent(true);
  }, [email]);

  return (
    <Container maxWidth={false} disableGutters className={styles.root}>
      <Grid container alignContent="center" className={styles.container}>
        {liveStreamStatus === 'LOADING' &&
          <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={80} color="secondary" />
          </Box>
        }
        {(!user && !loading && !emailLinkSent) &&
          <>
            <Grid xs={12} sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4} className={styles.logoContainer}>
              <img src="/logo-large.png" alt="Edfluence" />
            </Grid>
            <Grid xs={12} sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4} className={styles.textContainer}>
              <EdTypography variant="subtitle1" textAlign="center">{dataEvent.title}</EdTypography>
              <EdTypography textAlign="center">{dataEvent.description}</EdTypography>
            </Grid>
            <Grid xs={12} sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4} className={styles.actionsContainer}>
              <TextField
                className={styles.txfEmail}
                value={email}
                onChange={onChangeEmail}
                label="Email"
                required
                type="email"
                size="small"
                fullWidth
                error={errorEmail || errorFormatEmail}
                helperText={
                  (errorEmail && 'The Email is required') || (errorFormatEmail && 'The email format is invalid')
                }
                sx={{
                  '& label.Mui-focused': {
                    color: '#697586'
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#697586',
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                fullWidth
                size="large"
                startIcon={<MailOutlineIcon />}
                className={styles.btnLogin}
                onClick={handleSendEmailLink}
              >
                Log in with email
              </Button>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                startIcon={<SvgIcon component={GoogleIcon} />}
                className={styles.btnSocial}
                onClick={signInWithGoogle}
              >
                Log in with Google
              </Button>
            </Grid>
          </>
        }
        {(emailLinkSent && !user) &&
          <EdTypography padding={8}>Link sent, please check you E-mail inbox and click on the link to start!</EdTypography>
        }
        {(liveStreamStatus === 'SCHEDULED' && user && dataEvent) && <Grid sm={12}><WaitingScreen dataEvent={dataEvent} /></Grid>}
        {(liveStreamStatus === 'IN_PROGRESS' && user && dataEvent) &&
          <>
            <EdDialog
              id="comments-dialog"
              icon="forum"
              onClose={() => setOpenChat(false)}
              open={openChat}
              title="Comments"
            >
              <Chat messages={comments} onSend={handleSendMessage} />
            </EdDialog>
            <div className={styles.videoContainer}>
              <div className={styles.endCallContainer}>
                <IconButton onClick={handleEndCall} className={styles.btnEndCall}>
                  <Icon sx={{ color: 'white' }}>keyboard_backspace</Icon>
                </IconButton>
                <div className={styles.title}>{dataEvent.title}</div>
              </div>
              <AgoraUIKit
                rtcProps={{
                  appId: config.agora.appId,
                  channel: dataEvent?.channel_name,
                  token: dataEvent?.streaming_token, //add your token if using app in secured mode
                  role: 'audience',
                  layout: layout.grid
                }}
                rtmProps={{ username: 'user', displayUsername: false }}
                callbacks={{
                  EndCall: handleEndCall
                }}
                styleProps={{
                  UIKitContainer: {
                    position: 'relative',
                    background: 'transparent'
                  },
                  localBtnContainer: {
                    display: 'none'
                  },
                  localBtnStyles: {
                    endCall: {}
                  }
                }}
              />
            </div>
            <div className={styles.chatIconContainer} onClick={() => { setOpenChat(true); setCommentsCount(0); }}>
              <img className={styles.icon} src={ChatIcon} alt="chat" />
              <span>{commentsCount}</span>
            </div>
            <EdDialog
              id="question-dialog"
              icon={questionTitle?.icon}
              onClose={() => setOpenQuestion(false)}
              open={openQuestion}
              title={questionTitle?.title}
            >
              {question?.type === QuestionType.SpeakTheSentence && (
                <EdSpeakTheSentence
                  sentence={question?.sentence}
                  onAnswer={(e: any) => handleOnAnswer({ ...e, question_id: question?.id, question_launched_date: question?.question_launched_date })}
                  language={dataEvent?.target_language}
                />
              )}
              {question?.type === QuestionType.TypeTheWords && (
                <TypeTheWords
                  sentence={question?.sentence}
                  onContinue={handleOnCloseDialog}
                  onAnswer={(e: any) => handleOnAnswer({ ...e, question_id: question?.id, question_launched_date: question?.question_launched_date })}
                />
              )}
              {question?.type === QuestionType.MultipleChoice && (
                <MultipleChoice
                  sentence={question?.sentence}
                  acceptedAnswers={question?.accepted_answers}
                  distractors={question?.distractors}
                  onContinue={handleOnCloseDialog}
                  onAnswer={(e: any) => handleOnAnswer({ ...e, question_id: question?.id, question_launched_date: question?.question_launched_date })}
                />
              )}
              {question?.type === QuestionType.CTA && (
                <Cta
                  data={question}
                  onAnswer={(e) => handleOnAnswer({ ...e, question_id: question?.id, question_launched_date: question?.question_launched_date })}
                />
              )}
            </EdDialog>
          </>
        }
        {(liveStreamStatus === 'DONE' && user && dataEvent) && <Grid sm={12}><DoneScreen dataEvent={dataEvent} /></Grid>}
      </Grid>
    </Container>
  );
}

export default React.memo(LiveSteaming);
