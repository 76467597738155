const config = {
  env: 'production',
  api: {
    security: {
      basePath: 'https://security-v2.edfluence.app/api'
    },
    events: {
      basePath: 'https://events.edfluence.app/api'
    },
    lessons: {
      basePath: 'https://lessons.edfluence.app/api'
    }
  },
  socket: {
    chat: {
      basePath: 'https://chat.edfluence.app'
    }
  }
}

export default config;
