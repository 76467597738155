import axios from 'axios';

import config from '../config';

class LessonService {
  static async getLessonById(id: any) {
    try {
      const response = await axios({
        url: `${config.endpoints.lesson.index}/${id}`,
        method: 'GET'
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  static async postAnswerQuestion(lessonId: any, data: { uuid: string, email: string | null | undefined, answer: string, question_id: number, is_correct: boolean }) {
    try {
      const response = await axios({
        url: `${config.endpoints.lesson.index}/${lessonId}/answer`,
        method: 'POST',
        data
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }
}

export default LessonService;
