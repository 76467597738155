import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { v4 as uuid } from 'uuid';
import { AvatarGenerator } from 'random-avatar-generator';

import { SocketProvider } from './context/socketContext';
import { UsersProvider } from './context/usersContext';

import App from './App';

import reportWebVitals from './reportWebVitals';

import './styles/index.css';

const generator = new AvatarGenerator();

const socketHostUuid = localStorage.getItem('ed-socket-host-uuid');
const socketAudienceUuid = localStorage.getItem('ed-socket-audience-uuid');
if (!socketHostUuid) {
  localStorage.setItem('ed-socket-host-uuid', uuid());
}

if (!socketAudienceUuid) {
  localStorage.setItem('ed-socket-audience-uuid', uuid());
}

if (!localStorage.getItem('edfluence-audience-image')) {
  localStorage.setItem('edfluence-audience-image', generator.generateRandomAvatar());
}

const theme = createTheme({
  palette: {
    secondary: {
      main: '#DD2590',
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <UsersProvider>
          <SocketProvider>
            <App />
          </SocketProvider>
        </UsersProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
