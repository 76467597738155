import React, { useCallback } from 'react';
import { Chip, Dialog, DialogContent, Radio } from '@mui/material';
import { DialogActions, Icon, Button } from '@mui/material';
import { FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';

import DialogTitle from '../../components/Dialog/DialogTitle';

import { QuestionType } from '../../Enums/Event';

import styles from './paper.module.css';

type ShowInteractionDialogProps = {
  onLaunchInteraction: (interaction: any) => void;
  onClose: () => void;
  interaction: any;
};

const ShowInteractionDialog: React.FC<ShowInteractionDialogProps> = (
  props: ShowInteractionDialogProps
) => {
  const {
    interaction,
    onLaunchInteraction,
    onClose,
  }: ShowInteractionDialogProps = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderInteractionTitle = useCallback((interaction: any) => {
    switch (interaction?.type) {
    case QuestionType.SpeakTheSentence:
      return (
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
                microphone
            </Icon>
          }
          label="Speaking practice"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );
    case QuestionType.MultipleChoice:
      return (
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
                format_list_bulleted
            </Icon>
          }
          label="Multiple choice"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );
    case QuestionType.TypeTheWords:
      return (
        <Chip
          icon={
            <Icon fontSize="small" sx={{ color: '#C11574 !important' }}>
                text_fields
            </Icon>
          }
          label="Type words"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );
    case QuestionType.CTA:
      return (
        <Chip
          icon={
            <RocketLaunchOutlinedIcon sx={{ color: '#C11574 !important', fontSize: '14px' }} />
          }
          label="CTA"
          sx={{ color: '#C11574', background: '#FDF2FA', fontSize: '14px' }}
        />
      );

    default:
      break;
    }
  }, []);

  const renderInteractionSentence = useCallback((interaction: any) => {
    switch (interaction?.type) {
    case QuestionType.TypeTheWords:
    case QuestionType.SpeakTheSentence:
    case QuestionType.CTA:
      return interaction?.sentence ?? '';
    case 'multipleChoice':
      const answers = [
        ...interaction?.accepted_answers,
        ...interaction?.distractors,
      ].sort();

      return (
        <FormControl>
          <RadioGroup value={undefined} name="answer">
            {answers.map((element, key) => (
              <FormControlLabel
                key={key}
                value={element}
                control={<Radio />}
                label={element}
                disabled
              />
            ))}
          </RadioGroup>
        </FormControl>
      );

    default:
      break;
    }
  }, []);
  return (
    <>
      <Dialog
        open
        classes={{
          paper: fullScreen ? styles.dialog : styles.webDialog,
        }}
      >
        <DialogTitle id="interactions-dialog-title" onClose={onClose}>
          {renderInteractionTitle(interaction)}
        </DialogTitle>
        <DialogContent>{renderInteractionSentence(interaction)}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onLaunchInteraction(interaction);
            }}
            sx={{
              textTransform: 'none',
              borderRadius: '100px',
              background: '#DD2590',
              '&:hover': {
                bgcolor: '#DD2590',
              },
            }}
          >
            Launch
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(ShowInteractionDialog);
