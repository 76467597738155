export enum QuestionType {
    SpeakTheSentence = 'speakTheSentence',
    MultipleChoice = 'multipleChoice',
    TypeTheWords = 'typeTheWords',
    SpeakTheAnswer = 'speakTheAnswer',
    CTA = 'cta',
    Poll = 'poll',
    OptIn = 'optIn'
}

export enum LanguageEnum {
  en = 'en-US',
  'en-US' = 'en-US',
  es = 'es-MX',
  'es-MX' = 'es-MX',
  'it-IT' = 'it-IT',
  'it' = 'it-IT',
  'es-ES' = 'es-ES',
  'lt-LT' = 'lt-LT',
  'fi-FI' = 'fi-FI'
}
