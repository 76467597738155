import React from 'react';
import { Button } from '@mui/material';

import EdTypography from '../EdTypography';
import EdButton from '../EdButton';
import { ReactComponent as CheckIcon } from '../../img/check.svg';

import styles from './Cta.module.css';

interface CtaProps {
  data: QuestionData;
  onAnswer: (data: any) => any;
  optIn?: boolean;
}

function Cta({ data, onAnswer, optIn }: CtaProps) {
  const [subscribed, setSubscribed] = React.useState(false);
  const onSubscribed = () => {
    setSubscribed(true);
    onAnswer({
      answer: 'subscribed',
      is_correct: true
    });
  };
  return (
    <div className={styles.root}>
      <img className={styles.img} src={data.image} alt={data.header} />
      <EdTypography variant="h5" align="center" marginBottom="16px">{data.header}</EdTypography>
      <EdTypography variant="subtitle2">{data.sentence}</EdTypography>
      <div className={styles.btnContainer}>
        {(data.secondary_link && data.secondary_text) &&
          <a
            href={data.secondary_link}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            onClick={() => onAnswer({
              answer: data.secondary_link,
              is_correct: true
            })}
          >
            <Button
              className={styles.btn}
              variant="contained"
              size="large"
              fullWidth
            >
              {data.secondary_text}
            </Button>
          </a>
        }
        {(data.primary_link && data.primary_text) &&
          <a
            href={data.primary_link}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            onClick={() => onAnswer({
              answer: data.primary_link,
              is_correct: true
            })}
          >
            <Button
              className={styles.btn}
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
            >
              {data.primary_text}
            </Button>
          </a>
        }
        {(data.primary_text && optIn) && (
          <div className={styles.optInBtnContainer}>
            <EdButton
              variant={subscribed ? 'text' : 'contained'}
              onClick={onSubscribed}
              startIcon={subscribed ? <CheckIcon /> : <></>}
            >
              {subscribed ? data.secondary_text : data.primary_text}
            </EdButton>
          </div>
        )}
      </div>

    </div>
  );
}

export default React.memo(Cta);
