import React from 'react';
import io from 'socket.io-client';

import config from '../config';

const SocketContext = React.createContext<any>({});

const SocketProvider = ({ children }: any) => {
  const ENDPOINT = config.socket.chat.basePath;
  const socket = io(ENDPOINT, { transports: ['websocket', 'polling'] });
  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };