import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import difflib from 'difflib';

import config from '../../config';

import { LanguageEnum } from '../../Enums/Event';

interface Results {
  scores: {
    accuracyScore: number,
    completenessScore: number,
    fluencyScore: number,
    pronunciationScore: number
  };
  isCorrect: boolean;
  answer: string | null;
}


function getScores(event: speechsdk.SpeechRecognitionEventArgs, sentence: string) {
  let jo: any = {};
  let startOffset = 0;
  let currentText: any[] = [];
  const fluencyScores = [];
  const recognizedWords: any[] = [];
  const durations: any[] = [];
  const allWords: any[] | null | undefined = [];
  const scores = {
    accuracyScore: 0,
    fluencyScore: 0,
    completenessScore: 0,
    pronunciationScore: 0
  };

  jo = eval('(' + event.result.properties.getProperty(speechsdk.PropertyId.SpeechServiceResponse_JsonResult) + ')');
  const nb = jo['NBest'][0];
  startOffset = nb.Words[0].Offset;
  const localtext = nb.Words.map((item: { Word: string; }) => item.Word.toLowerCase());
  currentText = currentText.concat(localtext);
  fluencyScores.push(nb.PronunciationAssessment.FluencyScore);
  const isSucceeded = jo.RecognitionStatus === 'Success';
  const nBestWords = jo.NBest[0].Words;
  const durationList: any[] = [];
  nBestWords.forEach((word: { Duration: any; }) => {
    recognizedWords.push(word);
    durationList.push(word.Duration);
  });
  durations.push(durationList.reduce((a, b) => a + b));

  if (isSucceeded && nBestWords) {
    allWords.push(...nBestWords);
  }

  const resText = currentText.join(' ');
  let wholelyricsArry: any[] = [];
  let resTextArray: any[] = [];

  const resTextProcessed = (resText.toLocaleLowerCase() ?? '').replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', 'g'), '').replace(new RegExp(']+', 'g'), '');
  const wholelyrics = (sentence.toLocaleLowerCase() ?? '').replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', 'g'), '').replace(new RegExp(']+', 'g'), '');
  wholelyricsArry = wholelyrics.split(' ');
  resTextArray = resTextProcessed.split(' ');
  const wholelyricsArryRes = wholelyricsArry.filter((item) => !!item).map((item) => item.trim());

  // For continuous pronunciation assessment mode, the service won't return the words with `Insertion` or `Omission`
  // We need to compare with the reference text after received all recognized words to get these error words.
  const diff = new difflib.SequenceMatcher(null, wholelyricsArryRes, resTextArray);
  const lastWords = [];
  for (const d of diff.getOpcodes()) {
    if (d[0] == 'insert' || d[0] == 'replace') {
      for (let j = d[3]; j < d[4]; j++) {
        if (allWords && allWords.length > 0 && allWords[j].PronunciationAssessment.ErrorType !== 'Insertion') {
          allWords[j].PronunciationAssessment.ErrorType = 'Insertion';
        }
        lastWords.push(allWords[j]);
      }
    }
    if (d[0] == 'delete' || d[0] == 'replace') {
      if (
        d[2] == wholelyricsArryRes.length &&
        !(
          jo.RecognitionStatus == 'Success' ||
          jo.RecognitionStatus == 'Failed'
        )
      )
        continue;
      for (let i = d[1]; i < d[2]; i++) {
        const word = {
          Word: wholelyricsArryRes[i],
          PronunciationAssessment: {
            ErrorType: 'Omission',
          },
        };
        lastWords.push(word);
      }
    }
    if (d[0] == 'equal') {
      for (let k = d[3], count = 0; k < d[4]; count++) {
        lastWords.push(allWords[k]);
        k++;
      }
    }
  }

  let reference_words = [];
  reference_words = wholelyricsArryRes;

  const recognizedWordsRes = [];
  recognizedWords.forEach((word) => {
    if (word.PronunciationAssessment.ErrorType == 'None') {
      recognizedWordsRes.push(word);
    }
  });

  let compScore = Number(((recognizedWordsRes.length / reference_words.length) * 100).toFixed(0));
  if (compScore > 100) {
    compScore = 100;
  }
  scores.completenessScore = compScore;

  const accuracyScores: any[] = [];
  lastWords.forEach((word) => {
    if (word && word?.PronunciationAssessment?.ErrorType != 'Insertion') {
      accuracyScores.push(Number(word?.PronunciationAssessment.AccuracyScore ?? 0));
    }
  });
  scores.accuracyScore = Number((accuracyScores.reduce((a, b) => a + b) / accuracyScores.length).toFixed(0));

  if (startOffset) {
    const sumRes: any[] = [];
    fluencyScores.forEach((x, index) => {
      sumRes.push(x * durations[index]);
    });
    scores.fluencyScore = sumRes.reduce((a, b) => a + b) / durations.reduce((a, b) => a + b);
  }

  if (
    jo.RecognitionStatus == 'Success' ||
    jo.RecognitionStatus == 'Failed'
  ) {
    scores.pronunciationScore = Number(
      (
        scores.accuracyScore * 0.6 +
        scores.completenessScore * 0.2 +
        scores.fluencyScore * 0.2
      ).toFixed(0)
    );
  } else {
    scores.pronunciationScore = Number(
      (scores.accuracyScore * 0.5 + scores.fluencyScore * 0.5).toFixed(0)
    );
  }
  return scores;
}

export function start(language: keyof typeof LanguageEnum, sentence: string, cb: (response: Results) => void) {
  let timer: any = null;
  const scores = {
    accuracyScore: 0,
    fluencyScore: 0,
    completenessScore: 0,
    pronunciationScore: 0
  };
  let isCorrect = false;
  let answer: string | null = null;
  const speechConfig = speechsdk.SpeechConfig.fromSubscription(config.azure.speechService.key, config.azure.speechService.region);
  speechConfig.speechRecognitionLanguage = LanguageEnum[language];

  const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

  const pronunciationAssessmentConfig = new speechsdk.PronunciationAssessmentConfig(
    sentence,
    speechsdk.PronunciationAssessmentGradingSystem.HundredMark,
    speechsdk.PronunciationAssessmentGranularity.Phoneme,
    true
  );
  const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
  pronunciationAssessmentConfig.applyTo(recognizer);
  recognizer.startContinuousRecognitionAsync();
  recognizer.recognized = (s, event: speechsdk.SpeechRecognitionEventArgs) => {
    let pronunciationResult;
    if (sentence) {
      pronunciationResult = getScores(event, sentence);
    } else {
      pronunciationResult = speechsdk.PronunciationAssessmentResult.fromResult(event.result);
    }
    scores.pronunciationScore = pronunciationResult.pronunciationScore;
    scores.accuracyScore = pronunciationResult.accuracyScore;
    scores.fluencyScore = pronunciationResult.fluencyScore;
    scores.completenessScore = pronunciationResult.completenessScore;
    isCorrect = Math.round(pronunciationResult.pronunciationScore) > 75;
    answer = event.result['privText'];
  };
  recognizer.recognizing = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      recognizer.stopContinuousRecognitionAsync();
      cb({
        scores,
        isCorrect,
        answer
      });
    }, 2000);
  };
  return { stop: () => { recognizer.stopContinuousRecognitionAsync(); } };
}


