import React from 'react';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';

import { QuestionType } from '../../Enums/Event';

import Question from './Question';

import styles from './CreateEventForm.module.css';

interface EventFormProps {
  channels: [any];
  onSave: (data: EventData) => void
}

const question = {
  type: QuestionType.SpeakTheSentence,
  sentence: '',
  distractors: [],
  acceptedAnswers: []
};

function EventForm({ channels, onSave }: EventFormProps) {
  const [form, setForm] = React.useState<EventForm>({
    txfTitle: '',
    txfDescription: '',
    txfStartDate: moment(),
    txfFinishDate: moment(),
    spokenLanguage: 'en',
    targetLanguage: 'en',
    channel: '',
    level: 'A1_1',
    questions: [
      question
    ]
  });

  React.useEffect(() => {
    if (channels?.length > 0) {
      setForm(values => ({
        ...values,
        channel: channels[0].name
      }));
    }
  }, [channels]);

  const onChangeInput = (event: any) => {
    const { name, value } = event.target;
    setForm(values => ({
      ...values,
      [name]: value
    }));
  };

  const handleOnQuestionChange = (data: QuestionForm, index: number) => {
    const questions = form.questions;
    questions[index] = data;
    setForm(values => ({
      ...values,
      questions
    }));
  };

  const handleAddQuestion = () => {
    const questions = form.questions;
    questions.push(question);
    setForm(values => ({
      ...values,
      questions
    }));
  };

  const handleDeleteQuestion = (index: number) => {
    const questions = form.questions.filter((_, key) => key !== index);
    setForm(values => ({
      ...values,
      questions
    }));
  };

  const handleSave = async () => {
    const questions = form.questions.map(question => ({
      type: question.type,
      sentence: question.sentence,
      distractors: question.distractors,
      accepted_answers: question.acceptedAnswers,
      header: question.ctaHeader,
      sub_header: question.ctaName,
      primary_text: question.ctaBtnPrimaryText,
      primary_link: question.ctaPrimaryLink,
      secondary_text: question.ctaBtnSecondaryText,
      secondary_link: question.ctaSecondaryLink,
      image: question.ctaImage
    }));
    const data = {
      title: form.txfTitle,
      description: form.txfDescription,
      start_date: moment(form.txfStartDate).utc().format(),
      finish_date: moment(form.txfFinishDate).utc().format(),
      channel_name: form.channel,
      spoken_language: form.spokenLanguage,
      target_language: form.targetLanguage,
      level: form.level,
      status: 'SCHEDULED',
      questions
    };
    onSave(data);
  };

  return (
    <form className={styles.root} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            name="txfTitle"
            value={form.txfTitle}
            label="Title"
            fullWidth
            variant="standard"
            onChange={onChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="txfDescription"
            value={form.txfDescription}
            label="Description"
            fullWidth
            variant="standard"
            onChange={onChangeInput}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MobileDateTimePicker
            label="Start Date"
            value={form.txfStartDate}
            onChange={(newValue: any) => {
              onChangeInput({
                target: {
                  name: 'txfStartDate',
                  value: newValue
                }
              });
            }}
            disablePast
            renderInput={(params) => <TextField {...params} fullWidth variant="standard" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MobileDateTimePicker
            label="Finish Date"
            value={form.txfFinishDate}
            onChange={(newValue: any) => {
              onChangeInput({
                target: {
                  name: 'txfFinishDate',
                  value: newValue
                }
              });
            }}
            disablePast
            minDateTime={form.txfStartDate}
            renderInput={(params) => <TextField {...params} fullWidth variant="standard" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="cmbChannelLabel">
              Channel
            </InputLabel>
            <Select
              labelId="cmbChannelLabel"
              label="Channel"
              name="channel"
              value={form.channel}
              onChange={onChangeInput}
              fullWidth
            >
              {channels?.map(element =>
                <MenuItem key={element.id} value={element.name}>{element.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="cmbSpokenLanguage">
              Spoken Language
            </InputLabel>
            <Select
              labelId="cmbSpokenLanguage"
              label=" Spoken Language"
              name="spokenLanguage"
              value={form.spokenLanguage}
              onChange={onChangeInput}
              fullWidth
            >
              <MenuItem value="en-US">English</MenuItem>
              <MenuItem value="es-MX">Spanish(Mexico)</MenuItem>
              <MenuItem value="es-ES">Spanish(Spain)</MenuItem>
              <MenuItem value="it-IT">Italian</MenuItem>
              <MenuItem value="lt-LT">Lithuanian</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="cmbTargetLanguage">
              Target Language
            </InputLabel>
            <Select
              labelId="cmbTargetLanguage"
              label="Target Language"
              name="targetLanguage"
              value={form.targetLanguage}
              onChange={onChangeInput}
              fullWidth
            >
              <MenuItem value="en-US">English</MenuItem>
              <MenuItem value="es-MX">Spanish(Mexico)</MenuItem>
              <MenuItem value="es-ES">Spanish(Spain)</MenuItem>
              <MenuItem value="it-IT">Italian</MenuItem>
              <MenuItem value="lt-LT">Lithuanian</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Questions</Typography>
          {form.questions.map((question, index) =>
            <React.Fragment key={index}>
              <Typography variant="subtitle2">
                Question No. {index + 1}
                <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteQuestion(index)}>
                  <DeleteIcon />
                </IconButton>
              </Typography>
              <Question
                data={question}
                onFormChange={(data) => handleOnQuestionChange(data as QuestionForm, index)}
              />
            </React.Fragment>
          )}
          <Button variant="contained" color="primary" onClick={handleAddQuestion}>Add Question</Button>
        </Grid>

        <Grid item xs={8} sm={4} margin="auto">
          <Button fullWidth variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default React.memo(EventForm);

