import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useParams, useSearchParams } from 'react-router-dom';
import YouTube, { YouTubeProps } from 'react-youtube';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import classNames from 'classnames';
// import MicIcon from '@mui/icons-material/Mic';
// import StopCircleIcon from '@mui/icons-material/StopCircle';

import EdDialogMobile from '../../components/Dialog';
import EdDialogDesktop from '../../components/EdDialog';
import LoginForm from './LoginForm';
import EdMicrophonePermissions from '../../components/EdMicrophonePermissions/EdMicrophonePermissions';
import EdSpeakTheSentence from '../../components/EdSpeakTheSentence';
import TypeTheWords from '../../components/TypeTheWords';
import MultipleChoice from '../../components/MultipleChoice';
import Cta from '../../components/Cta';
import EdFeedbackAlert from '../../components/EdFeedbackAlert';

import { ReactComponent as PulseIcon } from '../../img/pulse.svg';

import LessonService from '../../services/Lesson';
import UserService from '../../services/User';
import { auth, signInWithGoogle } from '../../services/firebase';

import { QuestionType } from '../../Enums/Event';

// import EdListeningWave from '../../components/EdListeningWave';

import styles from './Lesson.module.css';
import EdFeedback from '../../components/EdFeedback';

interface Alert {
  color: 'success' | 'warning' | 'neutral' | 'error' | undefined;
  message: string;
}

function EdDialog(props: any) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return mobile
    ? <EdDialogMobile id="question-dialog" {...props} />
    : <EdDialogDesktop {...props} />;

}

function Lesson() {
  const [player, setPlayer]: any = React.useState(null);
  const [lesson, setLesson] = React.useState<any>(null);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [questionToLaunch, setQuestionToLaunch] = React.useState<any>(null);
  const [questionTitle, setQuestionTitle] = React.useState<any>({});
  const [openQuestion, setOpenQuestion] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [user, setUser] = React.useState<any>(null);
  const [timeStop, setTimeStop] = React.useState(0);
  const [openLoginForm, setOpenLoginForm] = React.useState(false);
  const [openEdMicrophonePermission, setOpenEdMicrophonePermission] = React.useState(false);
  const [microphoneAvaible,setMicrophoneAvaible] = React.useState(false);
  const [openPulse, setOpenPulse] = React.useState(false);
  const [maxPoints, setMaxPoints] = React.useState(0);
  const [textAlertMicrophonePermission, setTextAlertMicrophonePermission] = React.useState('Ready to speak up? Enable your microphone to fully participate in interactive activities.');
  const [scoring, setScoring] = React.useState({
    speaking: 0,
    writing: 0,
    reading: 0
  });
  const [duration, setDuration] = React.useState(0);
  const [showMessage, setShowMessage] = React.useState(false);
  const [showBlur, setShowBlur] = React.useState(false);
  // const [openMic, setOpenMic] = React.useState(false);

  const [userLogged] = useAuthState(auth);
  const { id } = useParams();
  const userToken = searchParams.get('user_token');

  const handleOnCloseMicrophonePermission = () => {
    onPlay();
    setQuestionTitle({});
    setQuestionToLaunch(null);
    setOpenQuestion(false);
    setOpenPulse(false);
    setOpenEdMicrophonePermission(false);
  };

  const openMicrophonePermissionDialog = () => {
    onPause();
    setOpenQuestion(true);
    setQuestionTitle({ icon: '🎤', title: 'Microphone permisions' });
    setOpenEdMicrophonePermission(true);

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(()=>{
        setMicrophoneAvaible(true);
      });
  };

  React.useEffect(() => {
    openMicrophonePermissionDialog();
  }, []);

  React.useEffect(() => {
    if (userLogged && !openEdMicrophonePermission) {
      setQuestionTitle({});
      setOpenQuestion(false);
      setOpenLoginForm(false);
      setOpenPulse(false);
      onPlay();
    } else {
      openMicrophonePermissionDialog();
    }
  }, [userLogged]);

  React.useEffect(() => {
    LessonService.getLessonById(id).then(lesson => {
      const maxPointsLesson = lesson.questions.reduce((prev: any, current: { type: any; }) => {
        switch (current.type) {
        case QuestionType.MultipleChoice:
        case QuestionType.SpeakTheSentence:
        case QuestionType.TypeTheWords:
          prev += 1;
          break;
        default:
          break;
        }
        return prev;
      }, 0);
      setMaxPoints(maxPointsLesson);
      setLesson(lesson);
    });
    if (userToken) {
      UserService.getUserByToken(userToken).then(reponse => {
        setUser(reponse.data);
        console.log(user);
      });
    }
  }, []);

  React.useEffect(() => {
    if (openEdMicrophonePermission) {
      onPause();
    }

    if (!openQuestion && !openLoginForm && !userLogged && (Math.round(currentTime / 1000) >= 1) && (Math.round(currentTime / 1000) <= 12)) {
      setQuestionTitle({ icon: '📓', title: 'Track your progress' });
      setOpenQuestion(true);
      setOpenLoginForm(true);
      setOpenPulse(false);
    }
    if (timeStop > currentTime) {
      setTimeStop(0);
    }
    lesson?.questions.forEach((question: any, index: number) => {
      if (!questionToLaunch && currentTime >= question.milliseconds && currentTime <= (question.milliseconds + 100)) {
        if (currentTime >= (timeStop + 100)) {
          onPause();
          const title: any = {
            [QuestionType.SpeakTheSentence]: { icon: '🎙', title: 'Speaking' },
            [QuestionType.TypeTheWords]: { icon: '✍️', title: 'Fill the blanks' },
            [QuestionType.MultipleChoice]: { icon: '🚦', title: 'Multiple choice' },
            [QuestionType.Poll]: { icon: '📊', title: 'Poll' },
            [QuestionType.CTA]: { icon: 'rocket_launch', title: question.sub_header },
            [QuestionType.OptIn]: { icon: 'rocket_launch', title: question.sub_header }
          };
          setQuestionTitle(title[question.type]);
          setOpenQuestion(true);
          setQuestionToLaunch({ id: index, ...question });
          setOpenLoginForm(false);
          setOpenPulse(false);
        }
        setTimeStop(currentTime);
      }
    });
  }, [currentTime]);

  React.useEffect(() => {

    const segDuration = duration - 5000;

    if (currentTime >= segDuration && currentTime <= duration) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [currentTime, duration]);

  React.useEffect(() => {
    setShowBlur(!userLogged);
  }, [userLogged]);

  const opts: YouTubeProps['opts'] = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 1,
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
      iv_load_policy: 3,
      fs: 0
    },
  };

  const onReady: YouTubeProps['onReady'] = (event) => {
    setPlayer(event.target);
    setDuration(event.target.getDuration() * 1000);
    setInterval(() => {
      if (event.target.getPlayerState() === YouTube.PlayerState.PLAYING) {
        setCurrentTime(Math.round(event.target.getCurrentTime() * 1000));
      }
    }, 100);
  };

  const onPlay = () => {
    player?.playVideo();
  };

  const onPause = () => {
    player?.pauseVideo();
  };

  const handleOnAnswer = (data: any) => {
    const payload = {
      uuid: localStorage.getItem('ed-socket-audience-uuid') || '',
      email: userLogged?.email,
      answer: data.answer,
      question_id: data.question_id,
      is_correct: data.is_correct
    };
    const question = lesson.questions[data.question_id];
    switch (question.type) {
    case QuestionType.SpeakTheSentence:
      // eslint-disable-next-line no-case-declarations
      const answerJson = JSON.parse(data.answer);
      setScoring(value => {
        return {
          ...value,
          speaking: value.speaking + (answerJson.pronunciationScore / 100)
        };
      });
      break;
    case QuestionType.MultipleChoice:
      setScoring(value => {
        const newValue = value.reading + (data.is_correct ? 1 : 0);
        return {
          ...value,
          reading: newValue
        };
      });
      break;
    case QuestionType.TypeTheWords:
      const regex = /\*([^\\*]+?)\*/gmi;
      let aux;
      const words = [];
      const inputs = data.answer ? Object.values(JSON.parse(data.answer)) : [];
      let totalTrues = 0;
      while ((aux = regex.exec(question.sentence)) !== null) {
        words.push(aux[1].toLowerCase());
      }
      words.forEach(word => {
        if (inputs.includes(word)) {
          totalTrues++;
        }
      });
      setScoring(value => {
        return {
          ...value,
          writing: value.writing + parseFloat(((100 * totalTrues / words.length) / 100).toFixed(2))
        };
      });
      break;
    default:
      break;
    }
    LessonService.postAnswerQuestion(id, payload).then(() => {
      if (data.is_correct) {
        setTimeout(() => {
          handleOnCloseDialog();
        }, 2000);
      }
    });

  };

  const handleTextAlertMicrophonePermission = () => {
    setTextAlertMicrophonePermission('Please, before closing, enable your microphone');

    setTimeout(() => {
      setTextAlertMicrophonePermission('Ready to speak up? Enable your microphone to fully participate in interactive activities.');
    }, 3000);
  };

  const handleOnCloseDialog = (_?: any, reason?: string) => {
    if (reason === 'backdropClick') {
      return null;
    }
    onPlay();
    setQuestionTitle({});
    setQuestionToLaunch(null);
    setOpenQuestion(false);
    setOpenPulse(false);
  };

  const handleLoginWithGoogle = () => {
    onPause();
    signInWithGoogle();
  };

  const handleOpenPulse = () => {
    if (!openEdMicrophonePermission) {
      onPause();
      const title = { icon: '🧠', title: 'Your pulse' };
      setQuestionTitle(title);
      setOpenQuestion(true);
      setOpenLoginForm(false);
      setOpenPulse(true);
    } else {
      openMicrophonePermissionDialog();
    }
  };

  // const handleOpenMic = React.useCallback(() => {
  //   setOpenMic((value) => !value);
  // }, []);

  let alertColor: Alert = { color: 'success', message: '' };
  alertColor = React.useMemo<Alert>(() => {
    if (!lesson) {
      return alertColor;
    }
    const score = scoring.speaking + scoring.writing + scoring.reading;
    const feedBack = lesson?.feedback_rules;

    if (score <= feedBack?.bad.max) return { color: 'warning', message: feedBack.bad.message };
    if (score <= feedBack?.neutral.max) return { color: 'neutral', message: feedBack.neutral.message };
    if (score >= feedBack?.good.min) return { color: 'success', message: feedBack.good.message };
    return { color: 'success', message: '' };
  }, [scoring, lesson]);


  return (
    <div className={styles.root}>
      {lesson &&
        <>
          <YouTube
            className={styles.iframeContainer}
            videoId={lesson.youtube_video_id}
            onReady={onReady}
            opts={opts}
          />
          <div className={styles.controllers}>
            <PulseIcon className={styles.icon} onClick={handleOpenPulse} />
          </div>
          <EdDialog
            icon={questionTitle?.icon}
            onClose={handleOnCloseDialog}
            open={openQuestion}
            title={questionTitle?.title}
            layoutBlur={showBlur}
            onSignInWithGoogle={handleLoginWithGoogle}
            openEdMicrophonePermission={openEdMicrophonePermission}
            handleTextAlertMicrophonePermission={handleTextAlertMicrophonePermission}
          >
            {openPulse && (
              <div className={styles.pulseContainer}>
                <EdFeedback scoring={scoring} maxPoints={maxPoints} />
              </div>
            )}
            {openLoginForm && (
              <LoginForm onSignInWithGoogle={handleLoginWithGoogle} />
            )}
            {questionToLaunch?.type === QuestionType.SpeakTheSentence && (
              <EdSpeakTheSentence
                sentence={questionToLaunch?.sentence}
                onAnswer={(e: any) => handleOnAnswer({ ...e, question_id: questionToLaunch?.id, question_launched_date: questionToLaunch?.question_launched_date })}
                language={lesson?.target_language}
                onClose={handleOnCloseDialog}
              />
            )}
            {questionToLaunch?.type === QuestionType.TypeTheWords && (
              <TypeTheWords
                sentence={questionToLaunch?.sentence}
                onContinue={handleOnCloseDialog}
                onAnswer={(e: any) => handleOnAnswer({ ...e, question_id: questionToLaunch?.id, question_launched_date: questionToLaunch?.question_launched_date })}
              />
            )}
            {questionToLaunch?.type === QuestionType.MultipleChoice && (
              <MultipleChoice
                sentence={questionToLaunch?.sentence}
                acceptedAnswers={questionToLaunch?.accepted_answers}
                distractors={questionToLaunch?.distractors}
                onContinue={handleOnCloseDialog}
                onAnswer={(e: any) => handleOnAnswer({ ...e, question_id: questionToLaunch?.id, question_launched_date: questionToLaunch?.question_launched_date })}
              />
            )}
            {questionToLaunch?.type === QuestionType.Poll && (
              <MultipleChoice
                poll
                sentence={questionToLaunch?.sentence}
                acceptedAnswers={questionToLaunch?.accepted_answers}
                distractors={questionToLaunch?.distractors}
                onContinue={handleOnCloseDialog}
                onAnswer={(e: any) => handleOnAnswer({ ...e, question_id: questionToLaunch?.id, question_launched_date: questionToLaunch?.question_launched_date })}
              />
            )}
            {(questionToLaunch?.type === QuestionType.CTA || questionToLaunch?.type === QuestionType.OptIn) && (
              <Cta
                optIn={questionToLaunch?.type === QuestionType.OptIn}
                data={questionToLaunch}
                onAnswer={(e) => handleOnAnswer({ ...e, question_id: questionToLaunch?.id, question_launched_date: questionToLaunch?.question_launched_date })}
              />
            )}
            {/* <button onClick={logOut}>Logout</button> */}
            {openEdMicrophonePermission && (
              <EdMicrophonePermissions textAlert={textAlertMicrophonePermission} handleOnCloseMicrophonePermission={handleOnCloseMicrophonePermission} isAvaible={microphoneAvaible} setAvaible={setMicrophoneAvaible}/>
            )}
            {showMessage && (
              <div className={styles.message}>
                <EdFeedbackAlert color={alertColor?.color || 'success'} text={alertColor.message} />
              </div>
            )}

          </EdDialog>
        </>
      }
      {/* <div className={styles.micContainer}>
        <div
          className={classNames(styles.mic, { [styles.micOpened]: openMic })}
          onClick={handleOpenMic}
        >
          {openMic
            ? <>
              <EdListeningWave />
              <StopCircleIcon fontSize="large" sx={{ color: '#ffffff' }} />
            </>
            : <MicIcon fontSize="large" sx={{ color: '#ffffff' }} />
          }
        </div>
      </div> */}
    </div>
  );
}

export default Lesson;
